import React from "react";
// import Directory from '../../components/directory/directory.component'
// import { Container } from 'react-bootstrap'
// import "./homepage.styles.scss";

import Newsletter from "../newsletter/newsletter.component";
import ShopCollection from "../shop/shop-collection.page";
import Ourseries from "./ourseries.component";
import Partnerships from "./partnerships.component";
import Presentation from "./presentation-landing.component";
import Press from "./press.component";
import VideoLanding from "./video-landing.component";
const HomePage = () => {
  return (
    <>
      {/* <WebinarForm /> */}
      <VideoLanding />
      <Presentation />
      {/* <Hoodie /> */}
      <ShopCollection />
      <Ourseries />
      <Partnerships />
      <Press />
      {/* <VideoPlayerPresentation /> */}

      <Newsletter className="py-5" />
    </>
  );
};

export default HomePage;
