import React from 'react'

import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardCourse from '../../components/card-course/cardCourse.component'
import Donation from './donation.component'
const PresentationAcademyPage = () => {
  const alignCenter = { display: 'flex', alignItems: 'center' }

  return (
    <Parallax pages={5} className=' hide-scrollbar background-white'>
      <ParallaxLayer
        offset={0}
        style={{
          ...alignCenter,
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Donation className='py-5' />
      </ParallaxLayer>

      <ParallaxLayer sticky={{ start: 1, end: 2 }} speed={0.5}>
        <Row>
          <Col lg={6} className='position_center' />
          <Col
            lg={6}
            className='d-flex flex-column align-items-center justify-content-end mt-5 pt-5'
          >
            <CardCourse className='pt-5 mt-5' />
            <p>Exemple d'un cours</p>
          </Col>
        </Row>
      </ParallaxLayer>
      <ParallaxLayer sticky={{ start: 1, end: 1 }} speed={0.2}>
        <Row className='py-5'>
          <h5 className='text-center yellow bold'> NOUS SOUTENIR</h5>
          <h3 className='text-center mb-5'>
            LE MOOC POUR LES PERSONNES QUI (re)Découvrent le catholicisme
          </h3>
          <Col lg={6} className='position_center mt-5'>
            <div style={{ maxWidth: '600px' }} className='text-start'>
              <h4 className=' mb-3'>Quel Public ?</h4>
              <ul>
                <li>. Ceux qui redécouvrent la foi</li>
                <li>. Ceux qui se posent la question de la reconversion</li>
                <li>. Ceux qui veulent recevoir le baptême.</li>
              </ul>
            </div>
          </Col>
          <Col lg={6} className='position_center'></Col>
        </Row>
      </ParallaxLayer>
      <ParallaxLayer offset={2} speed={0.2}>
        <Row className='py-5'>
          <Col lg={6} className='position_center mt-5'>
            <div style={{ maxWidth: '600px' }} className='text-start'>
              <h4 className=' mb-3'>WEBSITE + APPLICATION DÉDIÉE AVEC :</h4>
              <ul>
                <li>. Version podcast</li>
                <li>. Version texte avec vidéo pour guider</li>
                <li>+ Exercices pratiques, quizz, astuces, vidéos bonus</li>
              </ul>
            </div>
          </Col>
          <Col lg={6} className='position_center'></Col>
        </Row>
      </ParallaxLayer>
    </Parallax>
  )
}
export default PresentationAcademyPage
