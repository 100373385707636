import React from "react";

import Col from "react-bootstrap/Col";

import { AnimationOnScroll } from "react-animation-on-scroll";
const CardArticle = ({ title, subtitle, description, side, link }) => {
  return (
    <Col md={6} sm={12} className="mt-5 px-5 ">
      <AnimationOnScroll
        animateOnce
        animateIn={`fade-in-${side}`}
        animateOut={`fade-out-${side}`}
      >
        <div className="press-column px-4 d-flex justify-content-between flex-column text-start py-2">
          <h5 className="mb-4 semi-bold">{title}</h5>
          <p className="mb-1">{subtitle}</p>
          <p className="mb-3">{description}</p>
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="btn-link yellow bold"
            href={link}
          >
            EN LIRE PLUS
          </a>
        </div>
      </AnimationOnScroll>
    </Col>
  );
};

CardArticle.defaultProps = {
  title: "",
  subtitle: "",
  description: "",
  link: "/",
  side: "left",
};
export default CardArticle;
