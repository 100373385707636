import React from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

const ModuleNavigation = () => {
  const navigate = useNavigate();
  const reduxCourse = useSelector((state) => state.course);
  const location = useLocation();
  const reduxCoursesValidated = useSelector((state) => state.coursesValidated);
  return (
    <Stack direction="horizontal" gap={1}>
      {reduxCourse && reduxCourse.chapters ? (
        <>
          {Object.entries(reduxCourse.chapters).map(([key, value]) => (
            <React.Fragment key={key}>
              {value.map((module) => {
                const idModule = location.pathname.split("/")[5];
                let stateButton = "not-completed";

                if (idModule === module.id) {
                  stateButton = "active";
                } else if (
                  reduxCoursesValidated &&
                  reduxCoursesValidated.includes(module.id)
                ) {
                  stateButton = "completed";
                }

                return (
                  <OverlayTrigger
                    key={module.id}
                    placement="bottom"
                    delay={{ show: 0, hide: 0 }}
                    overlay={
                      <Tooltip id="tooltip-id">{module.data.title}</Tooltip>
                    }
                  >
                    <Button
                      className={stateButton}
                      key={module.id}
                      disabled={!!!module.data.content}
                      variant="module"
                      onClick={() =>
                        navigate(
                          `/se-former/parcours-en-ligne/${reduxCourse.idCourse}/${key}/${module.id}`
                        )
                      }
                    />
                  </OverlayTrigger>
                );
              })}
            </React.Fragment>
          ))}
        </>
      ) : (
        <Spinner animation="border" variant="light" role="status" />
      )}
      {/* {chapters.map((chapter, index) => (
        <Button
          key={chapter.id + index}
          disabled={chapter.available ? false : true}
          variant="module"
          onClick={() =>
            navigate("/se-former/parcours-en-ligne/cours-1/" + chapter.id)
          }
        />
      ))} */}
    </Stack>
  );
};
export default ModuleNavigation;
