import React from "react";

import Button from "react-bootstrap/Button";
import CasquetteNavy from "../../assets/shop/casquettenavy.webp";

import "./card-article.scss";
import { useNavigate } from "react-router-dom";
const CardArticle = ({ link, src = CasquetteNavy, title, alt, price }) => {
  const navigate = useNavigate();
  return (
    <div className="position_center flex-column">
      <Button
        variant="empty"
        className="card-article"
        onClick={link && (() => navigate(link))}
      >
        <img src={src} alt={alt} />
      </Button>
      <div className="my-2 d-flex justify-content-between text">
        {title && <span className="h5 m-2">{title}</span>}
        {price && <span className="price m-2 bold">{price}</span>}
      </div>
    </div>
  );
};
export default CardArticle;
