import React from "react";

import Mormons from "../../assets/series/Mormons.webp";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
const SeriesColumn = ({
  title,
  category,
  imgSource,
  description,
  link,
  alt,
}) => {
  const navigate = useNavigate();
  return (
    <Col id="card-serie-column">
      <p className="grey-dark bold">{category}</p>
      <h5 className="white bold my-3">{title}</h5>
      <div style={{ width: "300px", height: "auto" }} className="mx-auto">
        <img
          src={imgSource}
          style={{ objectFit: "cover" }}
          alt={alt}
          className="glow mx-auto"
        />
      </div>
      <p className="white mt-3" style={{ width: "300px" }}>
        {description}
      </p>
      <div className="w-100 position_center mt-2">
        <Button variant="outline-light" onClick={() => navigate(link)}>
          Découvrir la série
        </Button>
      </div>
    </Col>
  );
};

SeriesColumn.defaultProps = {
  link: "https://www.youtube.com/watch?v=ivwIHVJCm6g&list=PLIJZUOTkGSE39dHziDzB8sxOcqhLzyXsk",
  category: "Critiques de Films",
  title: "Théologie et Popcorn",
  imgSource: Mormons,
  alt: "séries Critiques de films",
  description:
    "Des séries Netflix au blockbuster hollywoodien en passant par les animés japonais, je décrypte avec vous les films et séries du moment à la lumière de l'évangile.",
};
export default SeriesColumn;
