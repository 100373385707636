import React from "react";
import Container from "react-bootstrap/esm/Container";

import PresentationAcademy from "./presentation-academy.component";
const DonationPage = () => {
  return (
    <Container
      className="position_center background-white py-5 flex-column px-0"
      fluid
      style={{ minHeight: "100vh" }}
    >
      <PresentationAcademy />
    </Container>
  );
};

export default DonationPage;
