import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";

import SeriesColumn from "../../components/series-column/series-column.component";

import Row from "react-bootstrap/Row";

import { series } from "../../data/seriesData.js";
class MyCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToSlide: "",
    };
  }
  slides = [
    {
      key: series.lAuDela.key,
      content: (
        <SeriesColumn
          title={series.lAuDela.title}
          category={series.lAuDela.category}
          description={series.lAuDela.description}
          imgSource={series.lAuDela.imgSource}
          alt={series.lAuDela.alt}
          link={series.lAuDela.link}
        />
      ),
    },
    {
      key: series.sujetsTouchy.key,
      content: (
        <SeriesColumn
          title={series.sujetsTouchy.title}
          category={series.sujetsTouchy.category}
          description={series.sujetsTouchy.description}
          imgSource={series.sujetsTouchy.imgSource}
          alt={series.sujetsTouchy.alt}
          link={series.sujetsTouchy.link}
        />
      ),
    },
    {
      key: series.catechesePourAdulte.key,
      content: (
        <SeriesColumn
          title={series.catechesePourAdulte.title}
          category={series.catechesePourAdulte.category}
          description={series.catechesePourAdulte.description}
          imgSource={series.catechesePourAdulte.imgSource}
          alt={series.catechesePourAdulte.alt}
          link={series.catechesePourAdulte.link}
        />
      ),
    },
    {
      key: series.apprendreAPrier.key,
      content: (
        <SeriesColumn
          title={series.apprendreAPrier.title}
          category={series.apprendreAPrier.category}
          description={series.apprendreAPrier.description}
          imgSource={series.apprendreAPrier.imgSource}
          alt={series.apprendreAPrier.alt}
          link={series.apprendreAPrier.link}
        />
      ),
    },
    {
      key: series.religions.key,
      content: (
        <SeriesColumn
          title={series.religions.title}
          category={series.religions.category}
          description={series.religions.description}
          imgSource={series.religions.imgSource}
          alt={series.religions.alt}
          link={series.religions.link}
        />
      ),
    },
    {
      key: series.popcorn.key,
      content: (
        <SeriesColumn
          title={series.popcorn.title}
          category={series.popcorn.category}
          description={series.popcorn.description}
          imgSource={series.popcorn.imgSource}
          alt={series.popcorn.alt}
          link={series.popcorn.link}
        />
      ),
    },

    // {
    //   key: series.bestOf.key,
    //   content: (
    //     <SeriesColumn
    //       title={series.bestOf.title}
    //       category={series.bestOf.category}
    //       description={series.bestOf.description}
    //       imgSource={series.bestOf.imgSource}
    //       alt={series.bestOf.alt}
    //       link={series.bestOf.link}
    //     />
    //   ),
    // },
    {
      key: series.doctorLove.key,
      content: (
        <SeriesColumn
          title={series.doctorLove.title}
          category={series.doctorLove.category}
          description={series.doctorLove.description}
          imgSource={series.doctorLove.imgSource}
          alt={series.doctorLove.alt}
          link={series.doctorLove.link}
        />
      ),
    },
    {
      key: series.news.key,
      content: (
        <SeriesColumn
          title={series.news.title}
          category={series.news.category}
          description={series.news.description}
          imgSource={series.news.imgSource}
          alt={series.news.alt}
          link={series.news.link}
        />
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  render() {
    return (
      <div className="px-5 py-5 background-blue-dark">
        <h5 className="text-center yellow bold"> NOS SÉRIES</h5>
        <Row style={{ paddingBottom: "55px" }}>
          <div style={{ height: "800px", margin: "0 auto" }}>
            <Carousel
              slides={this.slides}
              goToSlide={this.state.goToSlide}
              offsetRadius="20"
              animationConfig={config.gentle}
              showNavigation={false}
            />
          </div>
        </Row>
      </div>
    );
  }
}
export default MyCarousel;
