import React from 'react'

import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import styles from './lav-chat-input.module.scss'
const LavChatInput = ({
  onSubmit,
  userQuestionInput,
  setUserQuestionInput,
  loading,
  setLoading
}) => {
  return (
    <form
      onSubmit={onSubmit}
      className={styles.inputContainer + ' row w-100 d-flex flex-row p-4'}
    >
      <Col xl={3} className='position_center'>
        <h5 className='mx-5 my-0'>L'Amour VaincrIA 0.1</h5>
      </Col>
      <Col xl={6} className='position_center'>
        <Form.Control
          type='text'
          name='question'
          className='mb-0 mr-3'
          placeholder='Qui est Jésus Christ ?'
          value={userQuestionInput}
          disabled={loading}
          onChange={e => setUserQuestionInput(e.target.value)}
        />

        <Button
          variant='empty'
          type='submit'
          className='grow'
          disabled={loading}
          //onClick={() => setLoading(true)}
          style={{ fontSize: '1.5rem' }}
        >
          <FontAwesomeIcon icon={faPaperPlane} className='xl secondary' />
        </Button>
      </Col>
      <Col xl={3} className='position_center' />
    </form>
  )
}
export default LavChatInput
