const localStorageScore = localStorage.getItem("scoreQCM");
const localStorageCoursesValidated = localStorage.getItem("coursesValidated");
const localStorageCoursesValidatedArray =
  localStorageCoursesValidated && localStorageCoursesValidated.split(",");
const initialState = {
  scoreQCM: localStorageScore ? parseInt(localStorageScore) : 0,
  coursesValidated: localStorageCoursesValidatedArray
    ? localStorageCoursesValidatedArray
    : 0,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    // gérer les différentes actions ici
    case "ADD_COURSE":
      return {
        ...state,
        course: action.payload,
      };
    case "ADD_COURSES_COLLECTION":
      return {
        ...state,
        coursesCollection: action.payload,
      };
    case "UPD_SCOREQCM":
      localStorage.setItem("scoreQCM", action.payload);
      return {
        ...state,
        scoreQCM: action.payload,
      };
    case "ADD_COURSES_VALIDATED":
      localStorage.setItem("coursesValidated", action.payload);
      return {
        ...state,
        coursesValidated: action.payload,
      };
    default:
      return state;
  }
}

export default rootReducer;
