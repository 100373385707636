import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import "./article.scss";
const Article = ({ idArticle = "8224782123347" }) => {
  const renderShopifyProduct = () => {
    var scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: "soutenir.lamourvaincra.fr",
        storefrontAccessToken: "d33fa5ae8e06a902eae3f856b76b7ba6",
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("product", {
          id: idArticle,
          node: document.getElementById("product-component-1677686740227"),
          moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                  "text-align": "left",
                },
                title: {
                  "font-family": "Montserrat, sans-serif",
                  "font-size": "26px",
                },
                button: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#e6b000",
                  },
                  "background-color": "#ffc300",
                  ":focus": {
                    "background-color": "#e6b000",
                  },
                  "border-radius": "5px",
                },
                price: {
                  "font-family": "Montserrat, sans-serif",
                  "font-size": "18px",
                },
                compareAt: {
                  "font-family": "Montserrat, sans-serif",
                  "font-size": "15.299999999999999px",
                },
                unitPrice: {
                  "font-family": "Montserrat, sans-serif",
                  "font-size": "15.299999999999999px",
                },
                description: {
                  "font-family": "Montserrat, sans-serif",
                },
              },
              layout: "horizontal",
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
                description: true,
              },
              width: "100%",
              text: {
                button: "Ajouter au panier",
              },
              googleFonts: ["Montserrat"],
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#e6b000",
                  },
                  "background-color": "#ffc300",
                  ":focus": {
                    "background-color": "#e6b000",
                  },
                  "border-radius": "5px",
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                description: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "14px",
                  color: "#4c4c4c",
                },
              },
              googleFonts: ["Montserrat"],
              text: {
                button: "Add to cart",
              },
            },
            option: {
              styles: {
                label: {
                  "font-family": "Montserrat, sans-serif",
                },
                select: {
                  "font-family": "Montserrat, sans-serif",
                },
              },
              googleFonts: ["Montserrat"],
            },
            cart: {
              styles: {
                button: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#e6b000",
                  },
                  "background-color": "#ffc300",
                  ":focus": {
                    "background-color": "#e6b000",
                  },
                  "border-radius": "5px",
                },
              },
              text: {
                title: "Panier",
                total: "Sous-total",
                empty: "Votre panier est vide",
                notice:
                  "Les codes d'expédition et de réduction sont ajoutés au moment du paiement.",
                button: "Vérifier",
              },
              googleFonts: ["Montserrat"],
            },
            toggle: {
              styles: {
                toggle: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "background-color": "#ffc300",
                  ":hover": {
                    "background-color": "#e6b000",
                  },
                  ":focus": {
                    "background-color": "#e6b000",
                  },
                },
              },
              googleFonts: ["Montserrat"],
            },
          },
        });
      });
    }
  };

  useEffect(() => {
    renderShopifyProduct();
  }, []);

  const navigate = useNavigate();
  return (
    <Container
      className="px-2 py-5 background-white mt-5"
      fluid
      style={{ minHeight: "95vh", overflowY: "hidden" }}
    >
      <Row>
        <Button
          variant="empty"
          onClick={() => navigate("/soutenir-shop")}
          className="d-flex align-items-center justify-content-start px-5 hover-primary"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="w-auto mx-3" />
          <h5 className="w-auto mb-0"> Retour à la liste d'articles</h5>
        </Button>
      </Row>
      <div id="product-component-1677686740227" className="pt-5"></div>
    </Container>
  );
};
export default Article;
