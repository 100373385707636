import React from "react";

import Container from "react-bootstrap/Container";

import { AnimationOnScroll } from "react-animation-on-scroll";
const Presentation = () => (
  <Container
    fluid
    className="background-white position_center flex-column py-5"
  >
    {/* <h5 className="text-center yellow bold"> PRÉSENTATION</h5> */}
    <AnimationOnScroll
      animateOnce
      animateIn="fade-in-front"
      animateOut="fade-out-front"
    >
      <h1 className="blue-dark text-center">
        Déjà plus de 300 000 abonnés sur Youtube
      </h1>
    </AnimationOnScroll>
    <AnimationOnScroll
      animateOnce
      animateIn="fade-in-top"
      animateOut="fade-out-top"
    >
      <h5 className="text-center semi-bold mb-4">
        {" "}
        Le seul prêtre pleinement consacré à l'évangélisation sur internet.
      </h5>
      <p className="text-center position_center">
        Suite au premier confinement, le frère Paul-Adrien a commencé à faire
        des vidéos sur YouTube.
        <br /> Aujourd'hui, il rassemble de nombreux catholiques et non
        catholiques autour de ses vidéos.
      </p>
      <div className="w-1000-md">
        <div className="video-player-container my-5">
          <iframe
            className="responsive-iframe"
            src="https://www.youtube.com/embed/4Ce0gxEIIO0?si=JOXMl4zZSJMKHY74?rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
    </AnimationOnScroll>
  </Container>
);

export default Presentation;
