import React from "react";
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RegisterInput } from "./register.component";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { auth } from "../../firestore";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import { useParams } from "react-router-dom";
const Register = () => {
  const navigate = useNavigate();

  const [error, setError] = useState({});
  const [key, setKey] = useState(1);

  //Personal Informations Artist
  const accountType = "Organisateur";
  const typeArtist = "";
  const sceneName = "";
  const preferencePrestation = "";

  const localisation = "";
  const phoneNumber = "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { accountTypeProps } = useParams();

  useEffect(() => {
    if (accountType === "organisateur") {
      setKey(3);
    }
  }, [accountType]);

  useEffect(() => {
    setError({});
  }, [accountType, email, password, confirmPassword]);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }, [accountTypeProps]);

  const handleClick = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError({ password: "Le mot de passe ne correspond pas" });
      return;
    }

    const userCredentials = {
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      location: localisation,
      accountType: accountType,
      typeArtist: typeArtist,
      title: sceneName,
      preferencePrestation,
    };

    axios
      .post("/user/signup", userCredentials)
      .then(async (res) => {
        if (res.data.token) {
          try {
            setPersistence(auth, browserSessionPersistence).then(async () => {
              await signInWithEmailAndPassword(auth, email, password);
              navigate("/");
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-between background-blue-dark py-5"
      style={{ minHeight: "95vh" }}
    >
      <Form>
        <div>
          <Row className="position_center ">
            <h3 className="title white">Inscrivez-vous</h3>
          </Row>
        </div>

        <Row className="w-100">
          <RegisterInput
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            keyIndex={key}
            error={error}
          />
        </Row>
        <div style={{ height: "60px" }}>
          {Object.keys(error).length >= 1
            ? Object.keys(error).map((key, ind) => {
                return (
                  <p key={key + ind} className="red">
                    {console.log(error)}
                    {error[key]}
                  </p>
                );
              })
            : null}
        </div>
        <div className="d-flex">
          <Row className="mb-3 mt-1 w-100 position_center">
            <div className="user-box">
              <Button
                variant="primary"
                size="lg"
                onClick={(e) => handleClick(e)}
                type="submit"
                disabled={accountType === ""}
              >
                Valider
              </Button>
            </div>
            <Button
              variant="empty"
              className="white hover-primary mt-2"
              onClick={() => navigate("/login")}
            >
              J'ai déjà un compte
            </Button>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default Register;
