import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardArticle from "../../components/card-article/card-article.component";
import Stack from "react-bootstrap/Stack";
import navy from "../../assets/shop/navy.webp";
import casquetteNavy from "../../assets/shop/casquettenavy.webp";
import poloFemmeNavy from "../../assets/shop/polo-femme-navy.webp";
import poloHommeNavy from "../../assets/shop/polo-homme-navy.webp";
import tShirtFemmeNavy from "../../assets/shop/t-shirt-f-navy.webp";
import tShirtHommeNavy from "../../assets/shop/t-shirt-navy.webp";

import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
const ShopCollection = () => {
  return (
    <Container
      fluid
      className="background-grey-light position_center flex-column py-5 flex-column min-vh-100"
    >
      <h5 className="text-center yellow bold"> NOUS SOUTENIR</h5>
      <h2 className="blue-dark text-center pt-5 pt-2">
        PARTICIPER à la mission
      </h2>
      <Row className="px-5 mx-5 position_center">
        <Badge bg="warning">
          <h5 className="mb-0">Winter is coming !</h5>
        </Badge>

        {/*           
            <img
              src={color}
              width="500"
              style={{ width: "100%" }}
              className="grow drop-shadow-black"
              alt="hoodie pull l'amour vaincra fr Paul Adrien"
            /> */}
        <Stack direction="vertical" className="px-5 ">
          <p className="bold mb-0 mt-5">Disponibles dans ces couleurs : </p>

          <Stack
            className="position_center flex-row  my-4"
            direction="horizontal"
            gap={4}
          >
            <FontAwesomeIcon icon={faCircle} color="#16161A" size="3x" />

            <FontAwesomeIcon icon={faCircle} color="#011E2D" size="3x" />

            <FontAwesomeIcon icon={faCircle} color="#5B5B5B" size="3x" />

            <FontAwesomeIcon icon={faCircle} color="#A0A0A1" size="3x" />

            <FontAwesomeIcon icon={faCircle} color="white" size="3x" />
          </Stack>
        </Stack>
      </Row>

      <Row>
        <Col lg={4}>
          <CardArticle
            alt={"Casquette L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/casquette"}
            src={casquetteNavy}
            title={"Casquette"}
            price={"10,00€"}
          />
        </Col>
        <Col lg={4}>
          <CardArticle
            alt={"Hoodie L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/hoodie"}
            src={navy}
            title={"Hoodie"}
            price={"39,50€"}
          />{" "}
        </Col>
        <Col lg={4}>
          <CardArticle
            alt={"Polo Femme L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/polo-femme"}
            src={poloFemmeNavy}
            title={"Polo Femme"}
            price={"22,00€"}
          />{" "}
        </Col>
        <Col lg={4}>
          <CardArticle
            alt={"Polo Homme L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/polo-homme"}
            src={poloHommeNavy}
            title={"Polo Homme"}
            price={"22,00€"}
          />{" "}
        </Col>
        <Col lg={4}>
          <CardArticle
            alt={"T-shirt Homme L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/tshirt-homme"}
            src={tShirtHommeNavy}
            title={"T-shirt Homme"}
            price={"15,00€"}
          />{" "}
        </Col>
        <Col lg={4}>
          <CardArticle
            alt={"T-shirt Femme L'Amour Vaincra Fr Paul Adrien"}
            link={"/soutenir-shop/tshirt-femme"}
            src={tShirtFemmeNavy}
            title={"T-shirt Femme"}
            price={"15,00€"}
          />{" "}
        </Col>
      </Row>
    </Container>
  );
};
export default ShopCollection;
