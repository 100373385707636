import React from "react";
import { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";

import LavChatInput from "../../components/lav-ia/lav-chatinput/LavChatInput";

import axios from "axios";

import { useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
export default function LAmourVaincrIA() {
  const [userQuestionInput, setUserQuestionInput] = useState("");

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [feedback, setFeedback] = useState("");
  const [contact, setContact] = useState("");

  const [messagesEnd, setMessagesEnd] = useState();
  const [toggle, setToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const submitFeedBack = () => {
    axios
      .post("/feedbacks", {
        name: name,
        surname: surname,
        input: messages.length > 2 && [
          messages[messages.length - 1],
          messages[messages.length - 2],
        ],
        contact: contact,
        feedback: feedback,
      })
      .then(() => {
        setToggle(false);
        setShowAlert(true);
      })
      .catch((err) => console.log(err));
  };

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  const fadeAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  async function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    let newMessages = [
      ...messages,
      { from: "user", message: userQuestionInput },
    ];

    setMessages(newMessages);
    try {
      const response = await axios
        .post(`/generate`, {
          user: JSON.stringify({ question: userQuestionInput }),
        })
        .then((response) => {
          return response;
        });

      const data = await response.data;
      setLoading(false);

      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }

      let newMessagesIA = [
        ...newMessages,
        { from: "fr-marc", message: data.result },
      ];
      setMessages(newMessagesIA);
      scrollToBottom();
    } catch (error) {
      // Consider implementing your own error handling logic here
      console.error(error);
      alert(error.message);
    }
  }

  return (
    <Container
      fluid
      className="full-height d-flex flex-column justify-content-between background-white px-0"
    >
      {/* <HeaderIA /> */}
      <Container className="pt-5">
        <ModalFeedback
          toggle={toggle}
          setToggle={setToggle}
          name={name}
          setName={setName}
          surname={surname}
          setSurname={setSurname}
          contact={contact}
          setContact={setContact}
          feedback={feedback}
          setFeedback={setFeedback}
          submitFeedBack={submitFeedBack}
        />
        <Row className="position_center mt-5">
          <Toast
            autohide={true}
            bg="success"
            className="p-0"
            show={showAlert}
            onClose={() => setShowAlert(false)}
          >
            <Toast.Header className="w-100">
              <strong className="mr-auto">Merci pour votre retour !</strong>
            </Toast.Header>
          </Toast>
        </Row>
        {messages.map((messageData, index) => {
          return (
            <>
              {messageData.from === "user" && (
                <Row key={index} className="my-3">
                  <Col md={5} />
                  <Col md={7} className="">
                    <div
                      className="pre-line message-box message-user secondary p-3"
                      style={fadeAnimation}
                    >
                      {messageData.message}
                    </div>
                  </Col>
                </Row>
              )}
              {messageData.from === "fr-marc" && (
                <Row key={index} className="my-3">
                  <Col md={7} className="secondary pre-line">
                    <div
                      className="pre-line message-box message-ia secondary p-3"
                      style={fadeAnimation}
                    >
                      <p>{messageData.message}</p>
                    </div>
                  </Col>
                  <Col md={2} className="position_center flex-row">
                    <Button
                      variant="empty"
                      onClick={() => setToggle(true)}
                      className="grow"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} size="2x" />
                    </Button>
                  </Col>
                  <Col md={3} />
                </Row>
              )}
            </>
          );
        })}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            setMessagesEnd(el);
          }}
        ></div>
        {loading && <Spinner animation="border" variant="dark" role="status" />}
      </Container>

      <LavChatInput
        onSubmit={onSubmit}
        setLoading={setLoading}
        loading={loading}
        setUserQuestionInput={setUserQuestionInput}
        userQuestionInput={userQuestionInput}
      />
    </Container>
  );
}

const ModalFeedback = ({
  setToggle,
  toggle,
  contact,
  setContact,
  name,
  setName,
  surname,
  setSurname,
  feedback,
  setFeedback,
  submitFeedBack,
}) => {
  return (
    <Modal centered show={toggle} onHide={() => setToggle(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Qu'avez-vous pensé ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="my-2">
            <Col>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Placeholder text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Placeholder text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Form.Label>Comment peut-on vous contacter ?</Form.Label>
            <Form.Control
              type="text"
              placeholder="example@gmail.com"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Row>

          <Row className="my-2">
            <Form.Label>
              Quel est votre avis sur cet IA et sur votre question ?
            </Form.Label>
            <Form.Control
              type="textarea"
              placeholder="Bien mais ..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />{" "}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => submitFeedBack()}>
          Envoyer
        </Button>
        <Button variant="secondary" onClick={() => setToggle(false)}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
