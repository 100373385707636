import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/logo-glow-yellow.webp";
const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <Container
      fluid
      className="background-grey-light position_center flex-column py-5 flex-column min-vh-100"
    >
      <h3>Merci de vous être inscrit !</h3>
      <img src={Logo} style={{ width: "100px", height: "100px" }} />
      <p>Votre inscription est bien confirmée.</p>
      <p>L'Amour Vaincra !</p>
      <Button onClick={() => navigate("/")}>Retour sur le site</Button>
    </Container>
  );
};
export default ThankYou;
