import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Input from "../../components/input/input";

import { auth } from "../../firestore";
import { signInWithEmailAndPassword } from "firebase/auth";

import Spinner from "react-bootstrap/esm/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/pro-light-svg-icons";
import { Stack } from "react-bootstrap";

const ModalPasswordForgot = ({ show, handleClose }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const handleReset = () => {
    axios
      .post("/user/resetPassword", { email: emailAddress })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <form>
        <Modal.Header closeButton>
          <Modal.Title>Rénitialiser son Mot de passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="px-4 my-4">
            <Input
              value={emailAddress}
              setValue={setEmailAddress}
              label="Votre adresse mail"
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleReset();
              handleClose();
            }}
            className="mx-5"
          >
            Rénitialiser votre mot de passe
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isInputFocused, setInputFocused] = useState(false);
  const [isInputUserFocused, setInputUserFocused] = useState(false);

  const map = {
    "auth/invalid-email": { email: "Veuillez saisir un mail valide" },
    "auth/user-not-found": { email: "Le mail n'existe pas" },
    "auth/wrong-password": { password: "Le mot de passe n'est pas correct" },
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          setLoading(false);
          if (location.state === "back") {
            navigate(-1);
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setError(map[error.code]);
        });
    } catch (err) {
      console.log("error", err);
      setError({
        general: "Un problème est survenu, veuillez réssayer plus tard",
      });
      setLoading(false);
    }
  };

  return (
    <Container
      fluid
      className="py-5 background-blue-dark"
      style={{ height: "100vh", flexDirection: "column" }}
    >
      <Row className="w-100 position_center login-box">
        <Col className="d-flex flex-column">
          <h3 className="text-center title my-5 w-auto white">Se connecter</h3>

          <form>
            <Row className=" fade-in-left m-auto" style={{ maxWidth: "800px" }}>
              <Col md={1} xs={1} className="position_center">
                <FontAwesomeIcon
                  icon={faUser}
                  size="xl"
                  className={isInputUserFocused ? "primary" : "white"} // Classe conditionnelle en fonction de l'état de focus
                />
              </Col>
              <Col md={11} xs={11}>
                <Stack className="user-box " direction="horizontal">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={error?.email ? " is-invalid" : ""}
                    pattern="\S+.*"
                    autoComplete="email"
                    required
                    onFocus={() => setInputUserFocused(true)} // Met à jour l'état de focus à true lorsqu'il est focus
                    onBlur={() => setInputUserFocused(false)} // Met à jour l'état de focus à false lorsqu'il perd le focus
                  />
                  <label className={error?.email ? " is-invalid" : ""}>
                    Adresse mail
                  </label>
                </Stack>
              </Col>
            </Row>
            <Row className=" fade-in-left m-auto" style={{ maxWidth: "800px" }}>
              <Col md={1} xs={1} className="position_center">
                <FontAwesomeIcon
                  icon={faLock}
                  size="xl"
                  className={isInputFocused ? "primary" : "white"} // Classe conditionnelle en fonction de l'état de focus
                />
              </Col>
              <Col md={11} xs={11}>
                <Stack className="user-box " direction="horizontal">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={error?.password ? " is-invalid" : ""}
                    autoComplete="password"
                    required
                    type="password"
                    pattern="\S+.*"
                    onFocus={() => setInputFocused(true)} // Met à jour l'état de focus à true lorsqu'il est focus
                    onBlur={() => setInputFocused(false)} // Met à jour l'état de focus à false lorsqu'il perd le focus
                  />
                  <label className={error?.password ? " is-invalid" : ""}>
                    Mot de passe
                  </label>
                </Stack>
              </Col>
            </Row>
            <Row className=" fade-in-left m-auto" style={{ maxWidth: "800px" }}>
              <div style={{ height: "60px" }} className="mb-0 py-2">
                <p className="red">{error.email}</p>

                <p className="red">{error.password}</p>
              </div>
            </Row>
            {error?.general ?? <p className="red">{error?.general}</p>}
            <div className="mt-5 mb-2">
              <Row className="d-flex position_center ">
                <Button
                  variant="primary"
                  onClick={(e) => handleClick(e)}
                  type="submit"
                  style={{ width: "225px" }}
                  size="lg"
                >
                  {loading ? (
                    <>
                      <Spinner
                        variant="secondary"
                        animation="border"
                        className="white"
                        role="status"
                        size="sm"
                      />
                    </>
                  ) : (
                    <>Se connecter</>
                  )}
                </Button>
              </Row>
            </div>
          </form>
          {/* <LoginPopUp /> */}
          <Button
            variant="empty"
            className="hover-primary white my-2"
            onClick={() => navigate("/register")}
          >
            S'inscrire
          </Button>
          <Button
            variant="empty"
            className="hover-primary white my-2"
            onClick={() => {
              // TODO page pour reset de password petre juste un pop up
              setShow(true);
            }}
          >
            Mot de passe oublié ?
          </Button>
          <ModalPasswordForgot show={show} handleClose={() => setShow(false)} />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
