import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Envelope from "../../assets/isometric/envelope.webp";
const Newsletter = ({ background = true, ...props }) => {
  return (
    <Container
      fluid
      className={
        background
          ? " px-5 d-flex background-blue-dark " + props.className
          : " px-5 d-flex " + props.className
      }
      style={{
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "top",
      }}
    >
      <h5 className="text-center yellow bold"> NEWSLETTER</h5>
      <Row className=" mx-0 w-100">
        <Col md={6} xs={12} className="position_center">
          <img
            src={Envelope}
            width="400"
            className="grow drop-shadow-white"
            style={{ width: "400px" }}
            alt="envelope fr Paul Adrien Newsletter"
          />
        </Col>
        <Col md={6} xs={12} className="mt-5 d-flex flex-column">
          <div className="px-5 position_center flex-column">
            <h2 className="white w-80 mx-auto">Ne rien louper</h2>
            <p className="white">
              Je souhaite rester informé des dernières nouveautés.
            </p>
          </div>
          <iframe
            data-w-type="embedded"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://sst8v.mjt.lu/wgt/sst8v/x01u/form?c=d22fb3a5"
            className="w-100"
            style={{ height: "400px" }}
          ></iframe>
          <script
            type="text/javascript"
            src="https://app.mailjet.com/pas-nc-embedded-v1.js"
          ></script>
        </Col>
      </Row>
    </Container>
  );
};

export default Newsletter;
