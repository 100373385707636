import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

// import CardArticle from "../../components/card-article/card-article.component";
import CardArticle from "../../components/card-press-article/card-press-article.component";

const Press = () => {
  return (
    <Container
      fluid
      className="background-grey-light position_center flex-column py-5"
    >
      <h5 className="text-center yellow bold"> PRESSE</h5>
      <h2 className="blue-dark text-center py-5">ILS PARLENT DE NOUS</h2>
      <Row className="px-5 mx-5 position_center">
        <CardArticle
          title="Famille Chrétienne"
          subtitle="Frère Paul-Adrien, dominicain et youtubeur, veut montrer un autre visage de l'Église"
          description="La chaîne YouTube de ce dominicain de 38 ans a franchi le cap des 3 000 abonnés. Décalé sur la forme, consistant sur le fond, le Frère Paul-Adrien s’adresse à tous."
          link="https://www.famillechretienne.fr/eglise/evangelisation/frere-paul-adrien-dominicain-et-youtubeur-veut-montrer-un-autre-visage-de-l-eglise-275234?utm_content=buffer8ed69&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer"
          side="left"
        />
        <CardArticle
          subtitle="Frère Paul Adrien d’Hardemare, le dominicain qui prêche sur YouTube"
          title="La Croix"
          description="À la faveur du confinement, les vidéos décalées du jeune religieux
              Frère Paul Adrien d’Hardemare sur YouTube ont vu leurs audiences
              s’envoler et sont même devenues son activité principale pour
              toucher un public jeune et éloigné de l’Église..."
          link="https://www.la-croix.com/Religion/Frere-Paul-Adrien-dHardemare-dominicain-preche-YouTube-2021-03-10-1201144937?utm_term=Autofeed&utm_medium=Social&utm_source=Twitter#Echobox=1615404894"
          side="right"
        />
      </Row>
      <Row className="px-5 mx-5">
        <CardArticle
          title="Le Parisien"
          subtitle="Evry-Courcouronnes : frère Paul Adrien, le prêtre aux milliers
              d’abonnés sur YouTube"
          description="Tournées au couvent dominicain d’Evry-Courcouronnes, ses vidéos
              totalisent 1,4 million de vues sur YouTube. Le frère Paul Adrien
              d’Hardemare y aborde les questions religieuses ..."
          link="https://www.leparisien.fr/essonne-91/evry-courcouronnes-frere-paul-adrien-le-pretre-aux-milliers-dabonnes-sur-youtube-25-08-2021-JWP5LG4XERGEDON7XHFKQXHWTA.php#xtor=AD-1481423553"
          side="left"
        />

        <CardArticle
          title="L'Incorrect"
          subtitle="FRÈRE PAUL-ADRIEN : STREAM THÉOLOGIQUE"
          description=" Alors que l'on imagine aisément les dominicains étudier les
              mystères théologiques dans de grandes bibliothèques, il paraît
              plus improbable d'en trouver dans un studio de mixage pour
              enregistrer des musiques à la gloire de Dieu."
          link="https://lincorrect.org/frere-paul-adrien-stream-theologique-portrait-lincorrect/"
          side="right"
        />
      </Row>
    </Container>
  );
};
export default Press;
