import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import StoreAuth from "./context/storeAuth";
import AuthContext from "./context/AuthContext";
ReactDOM.render(
  <BrowserRouter>
    <StoreAuth>
      <AuthContext>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthContext>
    </StoreAuth>
  </BrowserRouter>,
  document.getElementById("root")
);
