import Catechese from "../assets/series/catechese-poster.webp";
import Prier from "../assets/series/apprendre-a-prier-poster.webp";
import lAuDela from "../assets/series/l-audela-poster.webp";
import Popcorn from "../assets/series/theologie et popcorn-poster.webp";
import SujetsTouchy from "../assets/series/suejts-touchy-poster.webp";
import BestOf from "../assets/series/best-of-poster.webp";
import DoctorLove from "../assets/series/doctor-love-poster.webp";
import News from "../assets/series/news-poster.webp";
import Religions from "../assets/series/religions-poster.webp";

import CatecheseBanner from "../assets/series/catechese-banner.webp";
import PrierBanner from "../assets/series/apprendre-a-prier-banner.webp";
import lAuDelaBanner from "../assets/series/l-audela-banner.webp";
import PopcornBanner from "../assets/series/theologie et popcorn-banner.webp";
import SujetsTouchyBanner from "../assets/series/suejts-touchy-banner.webp";
import BestOfBanner from "../assets/series/best-of-banner.webp";
import DoctorLoveBanner from "../assets/series/doctor-love-banner.webp";
import NewsBanner from "../assets/series/news-banner.webp";
import ReligionsBanner from "../assets/series/religions-banner.webp";

export const series = {
  lAuDela: {
    key: "PLIJZUOTkGSE37aaNAWgKJBfcRlDYcYjfr",
    title: "L'au-delà",
    category: "Catéchèse",
    description:
      "À quoi ressemble l'au-delà ? Qu'est-ce qu'il y a après la mort ? Nous nous sommes tous déjà posés ces questions. À la lumière de l'Église catholique, je vous donne des éléménts de réponse.",
    imgSource: lAuDela,
    imgSourceBanner: lAuDelaBanner,
    alt: "Catéchèse",
    link: "/serie/lAuDela",
  },
  sujetsTouchy: {
    key: "PLIJZUOTkGSE39dHziDzB8sxOcqhLzyXsk",
    title: "Sujets touchy",
    category: "Vivre sa foi au XX1Ième siècle",
    description:
      "On met les pieds dans le plat en abordant les sujets délicats de société. Tu es jeune, catholique pratiquant ou non et tu te poses des questions sur ta vive affective et sexuelle, cette série est faite pour toi.",
    imgSource: SujetsTouchy,
    imgSourceBanner: SujetsTouchyBanner,
    alt: "Sujets délicats",
    link: "/serie/sujetsTouchy",
  },
  catechesePourAdulte: {
    key: "PLIJZUOTkGSE1IZYGyahm5cxg0fXGAZ2QE",
    title: "Catéchèse pour adulte",
    category: "Caté pour adulte",
    description:
      "Tu as plus de 30 ans et tu ne connais toujours pas le Christ et l'église, ou bien tu souhaites la redécouvrir ? À travers ce parcours de 7 vidéos découvre les bases du catéchisme et sur la vie de Jésus.",
    imgSource: Catechese,
    imgSourceBanner: CatecheseBanner,
    alt: "Catéchisme pour adulte",
    link: "/serie/catechesePourAdulte",
  },
  apprendreAPrier: {
    link: "/serie/apprendreAPrier",
    key: "PLIJZUOTkGSE3deVI-MSByWHrXiZHO0Q9M",
    title: "Apprendre à prier",
    category: "École de prière",
    description:
      "Prier c'est à la fois si simple et si compliqué ! Bien évidemment toi tu ne vois que le côté compliqué ! Découvre les fondamentaux de la prière pour qu'elle devienne ton chemin de sainteté !",
    imgSource: Prier,
    imgSourceBanner: PrierBanner,
    alt: "apprendre à prier",
  },
  religions: {
    key: "PLIJZUOTkGSE0lsESmQCA1iJQp-wJawZuk",
    title: "Religions",
    category: "Dialogue interreligieux",
    description:
      "Quelle est la différence entre un catholique et un protestant ? Entre un prêtre et un rabbin ? Découvre notre série sur les religions. Ambiance chill et bienveillante garantie.",
    imgSource: Religions,
    imgSourceBanner: ReligionsBanner,
    alt: "les religions",
    link: "/serie/religions",
  },
  popcorn: {
    key: "PLIJZUOTkGSE3ocR9ykEbiAK2QMC16Medx",
    title: "Théologie et Popcorn",
    category: "Culture Pop",
    description:
      "Vous passez votre temps à binge watcher des séries mais vous vous demandez si c'est bon pour votre vie spi ? Ou encore, vous avez appréciez ce blockbuster mais vous ne savez pas quoi en retenir ? Je vous donne mon analyse.",
    imgSource: Popcorn,
    imgSourceBanner: PopcornBanner,
    alt: "théologie et popcorn",
    link: "/serie/popcorn",
  },
  bestOf: {
    key: "PLIJZUOTkGSE0lsESmQCA1iJQp-wJawZuk",
    title: "Best Of",
    category: "Vidéos populaires",
    description:
      "Voici nos vidéos les plus populaires ! Une série regroupant les vidéos les plus connues de notre chaîne Youtube.",
    imgSource: BestOf,
    imgSourceBanner: BestOfBanner,
    alt: "best of videos",
    link: "/serie/bestOf",
  },
  doctorLove: {
    key: "PLIJZUOTkGSE1qKZ0onYWXJq2ZiURo6RPm",
    title: "Doctor Caritatis",
    category: "Vie spi et affective",
    description:
      "Vous avez des questions sur votre vie sentimentale ou affective ? Que vous soyez célibataire, marié ou en couple, je vous donne mes conseils.",
    imgSource: DoctorLove,
    imgSourceBanner: DoctorLoveBanner,
    alt: "doctor caritatis doctor love",
    link: "/serie/doctorLove",
  },
  news: {
    key: "PLIJZUOTkGSE1r1a9v0ztoAoH0YiNUDQlV",
    title: "Breaking News",
    category: "Actualités",
    description:
      "Que pensez de l'actualité ? De la guerre ? Des catastrophes naturelles ? Des scandales ? Je vous donne des repères pour décrypter l'actualité à la lumière des Évangiles.",
    imgSource: News,
    imgSourceBanner: NewsBanner,
    alt: "breaking news décrypter l'actualité",
    link: "/serie/news",
  },
};
