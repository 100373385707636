import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import Dominicain from "../../assets/partenariats/dominicain.webp";
import Saje from "../../assets/partenariats/saje.webp";
import Fonddubiencommun from "../../assets/partenariats/fonddubiencommun.webp";
import Youpray from "../../assets/partenariats/youpray.webp";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Partnerships = () => {
  return (
    <Container
      fluid
      className="background-white position_center flex-column py-5"
    >
      <h5 className="text-center yellow bold"> NOUS SOUTENIR</h5>
      <AnimationOnScroll
        animateOnce
        animateIn="fade-in-top"
        animateOut="fade-out-top"
      >
        <h2 className="blue-dark text-center py-5">ILS NOUS SOUTIENNENT</h2>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateOnce
        animateIn="fade-in-top"
        animateOut="fade-out-top"
      >
        <p className="text-center position_center">
          Le projet subsiste uniquement de dons. Nous avons donc besoin de vous
          pour financer la mission !
        </p>
        <p className="text-center position_center semi-bold my-2">
          Et vous ? Découvrez comment vous pourriez nous soutenir
        </p>
      </AnimationOnScroll>

      <AnimationOnScroll
        animateOnce
        animateIn="fade-in-top"
        animateOut="fade-out-top"
      >
        <Row className="justify-content-center">
          <Col md={2} sm={6} className="position_center px-5 py-5">
            <img
              src={Dominicain}
              style={{ objectFit: "contain" }}
              alt="logo Dominicains"
            />
          </Col>
          <Col md={2} sm={6} className="position_center px-5 py-5">
            <img
              src={Saje}
              style={{ objectFit: "contain" }}
              alt="logo Saje Distribution"
            />
          </Col>
          <Col md={2} sm={6} className="position_center px-5 py-5">
            <img
              src={Youpray}
              style={{ objectFit: "contain" }}
              alt="logo YouPray"
            />
          </Col>
          <Col md={2} sm={6} className="position_center px-5 py-5">
            <img
              src={Fonddubiencommun}
              style={{ objectFit: "contain" }}
              alt="logo Fond Du Bien Commun"
            />
          </Col>
        </Row>
      </AnimationOnScroll>
      <Row>
        <a href="/soutenir" rel="noopener noreferrer">
          <Button variant="primary" className="px-5 py-3">
            <h5 className="mb-0">NOUS SOUTENIR</h5>
          </Button>
        </a>
      </Row>
    </Container>
  );
};

export default Partnerships;
