export const addCourse = (course) => ({
  type: "ADD_COURSE",
  payload: course,
});

export const addCoursesCollection = (courses) => ({
  type: "ADD_COURSES_COLLECTION",
  payload: courses,
});

export const updateScore = (score) => ({
  type: "UPD_SCOREQCM",
  payload: score,
});
export const addCoursesValidated = (coursesValidated) => ({
  type: "ADD_COURSES_VALIDATED",
  payload: coursesValidated,
});
