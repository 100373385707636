import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
const Contact = () => {
  return (
    <Container
      fluid
      className="pt-5 background-white"
      style={{ minHeight: "95vh" }}
    >
      <Row className="text-center py-5">
        <h2>Contact</h2>
      </Row>
      <Row className="position_center">
        <h4>Contactez-nous par mail à l'adresse suivante</h4>
        <a
          href="mailto:contact@lamourvaincra.com"
          target="_blank"
          rel="noopener noreferrer"
          className="black"
        >
          <h5>contact@lamourvaincra.com</h5>
        </a>
      </Row>
    </Container>
  );
};
export default Contact;
