import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import axios from "axios";
// import Spinner from "react-bootstrap/esm/Spinner";
// import { Link } from "react-router-dom";
const VideoLanding = () => {
  // const [countYT, setCountYT] = useState("0");
  // const YBSubscribers = 110000;
  // const [countIG, setCountIG] = useState("0");
  // const IGSubscribers = 23000;
  // const [countDiscord, setCountDiscord] = useState("0");
  // const DiscordSubscribers = 6000;
  // const [countTikTok, setCountTikTok] = useState("0");
  // const TiktokSubscribers = 40000;

  // const counter = () => {
  //   for (let count = 0; count <= YBSubscribers; count = count + 10) {
  //     setTimeout(() => {
  //       setCountYT(count);
  //       if (count <= IGSubscribers) {
  //         setCountIG(count);
  //       }
  //       if (count <= DiscordSubscribers) {
  //         setCountDiscord(count);
  //       }
  //       if (count <= TiktokSubscribers) {
  //         setCountTikTok(count);
  //       }
  //     }, 1000);
  //   }
  // };

  // useEffect(() => {
  //   counter();
  // }, []);

  const calculateTimeLeft = () => {
    const now = new Date();
    const targetDate = new Date(Date.UTC(2024, 1, 14, 21, 0, 0)); // February 14, 2024 at 9 PM UTC

    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        jours: Math.floor(difference / (1000 * 60 * 60 * 24)), // Ajout des jours
        heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        secondes: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Col md={3} sm={12}>
        <div
          className=" bg-gray-100 rounded m-2"
          key={interval}
          style={{
            background: "white",
            padding: "10px",
            backgroundOpacity: "0.5",
            borderRadius: "10px",
          }}
        >
          {timeLeft[interval]} {interval}{" "}
        </div>
      </Col>
    );
  });

  return (
    <section
      className="section main-banner background-white "
      id="top"
      data-section="section1"
    >
      <div id="bg-video" className="background-blue-dark" />
      <div className="video-overlay header-text position_center h-100">
        <Container className="my-5 position_center flex-column h-100">
          <h2 className="white mb-3 mt-5 pb-2">
            L'AMOUR VAINCRA <span className="primary">ACADEMY !</span>
          </h2>

          <div className="position_center w-100 h-100">
            <iframe
              className="w-100 h-100"
              title="YouTube video player"
              frameBorder="0"
              src="https://www.youtube.com/embed/0_IY0ofyf98?si=dKUyqrG2VAMjztnF"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                objectFit: "cover",
                maxWidth: "1000px",
                height: "600px",
              }}
            />
          </div>
          {/* <p className="white" style={{ width: "350px" }}>
            Tenez-vous prêt pour notre parcours de carême 2024 ! <br />
            Pour cette année, L'Amour Vaincra vous propose un "vrai" Carême ! Au
            programme, des formations avec cours en ligne et live, des défis et
            pleins d'autres surprises !
          </p>
          <a
            href={"https://academy.lamourvaincra.com/"}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-link w-auto"
          >
            <Button size="lg" className="my-5">
              Rejoindre le parcours carême
            </Button>
          </a> */}
        </Container>
      </div>
    </section>
  );
};

export default VideoLanding;
