import React from "react";

import Card from "react-bootstrap/Card";
import "./card-course.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faClock } from "@fortawesome/pro-light-svg-icons";
import { Button } from "react-bootstrap";
import Careme from "../../assets/vignette/colombe-course.webp";
import { useNavigate } from "react-router-dom";
const CardCourse = ({
  srcImage = "https://placeimg.com/300/120/any",
  title = "DÉCOUVRIR LE CHRISTIANISME",
  subtitle = "PARCOURS DE BASE",
  courseDuration = 10,
  nbChapters = 3,
  description = "",
  idCourse = "",
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() =>
        navigate(`/se-former/parcours-en-ligne/${idCourse}`, {
          state: { idCourse: idCourse },
        })
      }
      variant="empty"
      className={props.className ? props.className : "w-auto"}
    >
      <Card className="grow">
        <Card.Img variant="top" src={Careme} />
        <Card.Body className="d-flex flex-column align-items-start px-3 py-3">
          <Card.Subtitle className="text-start">{title}</Card.Subtitle>
          <Card.Text className="text-start light">{description}</Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex border-yellow">
          <span>
            <FontAwesomeIcon icon={faClock} className="mr-2" />
            {courseDuration} Heures
          </span>
          <span>
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            {nbChapters} Chapitres
          </span>
        </Card.Footer>
      </Card>
    </Button>
  );
};
export default CardCourse;
