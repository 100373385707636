import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Colombe from "../../assets/isometric/colombe.webp";
import axios from "axios";
import Select from "../../components/select/select";
const Donation = ({ background = true, ...props }) => {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [typeHelp, setTypeHelp] = useState(
    "Je souhaite faire une promesse de don."
  );
  const [donationTime, setDonationTime] = useState(
    "Je serai présent physiquement et j'annoncerai mon don en direct"
  );
  const [completed, setCompleted] = useState(false);
  const [erreurMessage, setErreurMessage] = useState(
    "Erreur, information manquante."
  );
  function setAmountPositive(value) {
    if (value >= 0) {
      setAmount(parseInt(value));
    }
  }
  useEffect(() => {
    if (
      typeHelp === "Je souhaite vous aider à tester le parcours." ||
      (prenom !== "" &&
        nom !== "" &&
        email !== "" &&
        amount !== "" &&
        amount !== 0 &&
        amount !== null &&
        donationTime !== "")
    ) {
      setErreurMessage("");
      setCompleted(true);
    } else {
      if (prenom === "") {
        setErreurMessage("Compléter les informations avec votre prénom.");
      }
      if (nom === "") {
        setErreurMessage(
          "Compléter les informations avec votre nom de famille."
        );
      }
      if (amount === "" && amount === 0 && amount === null) {
        setErreurMessage(
          "Compléter les informations avec la somme de votre promesse de don."
        );
      }
      if (email === "" || !email.includes("@")) {
        setErreurMessage("Compléter les informations avec votre adresse mail.");
      }
      if (donationTime === "") {
        setErreurMessage(
          "Compléter les informations avec les modalités de votre promesse de don."
        );
      }

      setCompleted(false);
    }
  }, [prenom, nom, email, amount, donationTime, typeHelp]);

  function resetFields() {
    setPrenom("");
    setNom("");
    setAmount("");
    setEmail("");
  }

  function sendDonation() {
    const request = {
      name: prenom,
      surname: nom,
      email: email,
      amount: amount,
      donationType: donationTime,
      typeHelp: typeHelp,
    };

    axios.post(`/donations`, request).then((res) => {
      alert("Merci pour votre promesse de don !");
      resetFields();
    });
  }

  return (
    <Container
      fluid
      className={
        background
          ? " px-5 d-flex background-blue-dark " + props.className
          : " px-5 d-flex " + props.className
      }
      style={{
        maxHeight: "100%",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "top",
      }}
    >
      <Row className=" mx-0 w-100">
        <Col md={6} xs={12} className="position_center">
          <img
            src={Colombe}
            width="400"
            className="grow drop-shadow-white"
            style={{ width: "400px" }}
            alt="envelope fr Paul Adrien Newsletter"
          />
        </Col>
        <Col md={6} xs={12} className="pt-5">
          <Row className="mb-2 ">
            <h3 className="white w-100">
              Soutenir le parcours en ligne catéchumène
            </h3>
            <p className="white text-start">
              Avec la{" "}
              <a
                href={"https://lanuitpourlamission.org/"}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link w-auto primary "
              >
                Nuit de la mission
              </a>
              , nous organisons une levée de fonds le 15 Juin. Grâce au couvent,
              vos dons pourront être défiscalisés. Vous pourrez assister à
              l'évènement sur place ou en direct ( nous vous communiquerons le
              lien). Pour préparer cette soirée et intéresser les investisseurs,
              nous avons besoin de vos promesses de dons ! Que Dieu vous
              bénisse.
              <br /> L'Amour Vaincra !
            </p>
          </Row>
          <Row style={{ maxWidth: "600px" }} className="w-100 mt-3">
            <div className="login-box">
              <form>
                <label className="w-100">
                  <h5
                    className="white w-100"
                    style={{
                      textAlign: "start",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Comment voulez-vous nous aider ?{" "}
                  </h5>
                  <Select
                    value={typeHelp}
                    className="w-100 mb-3"
                    setValue={setTypeHelp}
                    options={[
                      "Je souhaite faire une promesse de don.",
                      "Je souhaite vous aider à tester le parcours.",
                      "Les deux.",
                    ]}
                  />
                </label>
              </form>
            </div>
          </Row>

          {typeHelp !== "Je souhaite vous aider à tester le parcours." && (
            <Row style={{ maxWidth: "600px" }} className="w-100 mt-3">
              <div className="login-box">
                <form>
                  <label>
                    <h5
                      className="white"
                      style={{
                        textAlign: "start",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      Comment voulez-vous faire votre don ?
                    </h5>
                    <Select
                      value={donationTime}
                      className="w-100 mb-3"
                      setValue={setDonationTime}
                      options={[
                        "Je serai présent sur place et j'annoncerai mon don sur place",
                        "Je suivrai en direct sur internet et j'annoncerai mon don via le live",
                        "Je ne pourrai pas suivre la soirée : les organisateurs annonceront mon don pour moi",
                      ]}
                    />
                  </label>
                </form>
              </div>
            </Row>
          )}

          <Row style={{ maxWidth: "400px" }} className="w-100">
            <div className="login-box">
              <form>
                {typeHelp !==
                  "Je souhaite vous aider à tester le parcours." && (
                  <div className="user-box">
                    <Row>
                      <Col md={10} sm={10}>
                        <input
                          type="number"
                          value={amount}
                          onChange={(e) => setAmountPositive(e.target.value)}
                          required
                        />
                        <label>Montant de la promesse de don</label>
                      </Col>
                      <Col md={2} sm={2}>
                        <h5 className="white">€</h5>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="user-box">
                  <input
                    type="text"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    required
                  />
                  <label>Prénom</label>
                </div>
                <div className="user-box">
                  <input
                    type="text"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    required
                  />
                  <label>Nom</label>
                </div>
                <div className="user-box">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />

                  <label>Adresse email</label>
                </div>

                <div style={{ minHeight: "90px" }}>
                  {completed ? (
                    <Button size="lg" onClick={() => sendDonation()}>
                      Envoyer
                    </Button>
                  ) : (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 200 }}
                      overlay={
                        <Tooltip id="tooltip-id">{erreurMessage}</Tooltip>
                      }
                    >
                      <div
                        style={{
                          display: "inline-block",
                          cursor: "not-allowed",
                        }}
                      >
                        <Button size="lg" disabled={true}>
                          Envoyer
                        </Button>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              </form>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Donation;
