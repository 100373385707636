import React from "react";

import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import ModuleNavigation from "../module-navigation/moduleNavigation";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "./course-header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBook } from "@fortawesome/pro-light-svg-icons";

const CourseHeader = ({ course, chapter, module, navigate, setStepCourse }) => {
  return (
    <Row className=" py-4 mt-2  background-orange-50 course-header">
      <Breadcrumb>
        <Breadcrumb.Item href="/se-former/parcours-en-ligne">
          Liste des parcours
        </Breadcrumb.Item>
        {course.title && (
          <Breadcrumb.Item
            onClick={() => {
              setStepCourse(1);
              navigate(`/se-former/parcours-en-ligne/${course.idCourse}`);
            }}
          >
            {course.title}
          </Breadcrumb.Item>
        )}
        {module.title && (
          <Breadcrumb.Item
            onClick={() =>
              navigate(
                `/se-former/parcours-en-ligne/${course.idCourse}/${chapter.idChapter}/${module.idModule}`
              )
            }
          >
            {module.title}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <h3 className="black px-5"> {course.title}</h3>
      <div className="d-flex px-5" style={{ gap: "50px" }}>
        {!!course.duration && (
          <span>
            <FontAwesomeIcon icon={faClock} className="mr-2" />
            {course.duration} Heures
          </span>
        )}
        {!!course.length && (
          <span>
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            {course.length} Modules
          </span>
        )}
      </div>

      <Stack direction="horizontal" className="my-3 px-5">
        <ModuleNavigation />
      </Stack>
    </Row>
  );
};
export default CourseHeader;
