import React, { useEffect, useState } from "react";

import axios from "axios";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { addCourse } from "../../redux/actions";
const Course = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [chaptersList, setChaptersList] = useState([]);
  const [idCourse, setIdCourse] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    setIdCourse(location.pathname.split("/")[3]);
  }, []);

  useEffect(() => {
    if (idCourse !== "") {
      axios
        .get(`chapters/${idCourse}`)
        .then((response) => {
          setChaptersList(response.data);
          dispatch(
            addCourse({
              idCourse: idCourse,
              chapters: response.data,
            })
          );
        })

        .catch((err) => console.log(err));
    }
  }, [idCourse]);
  return (
    <React.Fragment>
      <Row className="position_center">
        <div className="w-1000-md">
          <div className="video-player-container my-5">
            <iframe
              className="responsive-iframe"
              src="https://www.youtube.com/embed/0i4UJhZ46aY?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </Row>

      <Container className="mt-5">
        <h4 className="black bold">Plan de la formation</h4>
        <hr />

        {Object.entries(chaptersList).map(([key, value]) => (
          <Container className="px-5 py-3" key={key}>
            <Row className="background-orange-50 py-3 px-5">
              <h4 className="mb-0"> {value[0].titleChapter}</h4>
            </Row>

            <Stack direction="vertical" className="my-3 px-5">
              {value.map((module, index) => (
                <Button
                  key={key + module.id + index}
                  onClick={() =>
                    navigate(
                      "/se-former/parcours-en-ligne/" +
                        idCourse +
                        "/" +
                        key +
                        "/" +
                        module.id
                    )
                  }
                  variant="empty"
                  className="hover-primary w-max-content"
                >
                  {module.data.title}
                </Button>
              ))}
            </Stack>
          </Container>
        ))}
      </Container>
    </React.Fragment>
  );
};
export default Course;
