import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
const MentionsLegales = () => {
  return (
    <Container
      fluid
      className="pt-5 background-white"
      style={{ minHeight: "95vh" }}
    >
      <Row className="text-center py-5">
        <h2>Mentions légales</h2>
      </Row>
      <Row className="px-5 py-2">
        <h4>Éditeur du site</h4>
        <p>
          Ce site a été développé par Kévin SANANIKONE. <br /> N'hésitez pas à
          visiter :<br />
          <a
            href={"https://kevinsananikone.com/"}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-link w-auto "
          >
            kevinsananikone.com
          </a>
        </p>
      </Row>
      <Row className="px-5 py-2">
        <h4>Association L'Amour Vaincra</h4>
        <p></p>
      </Row>
      <Row className="px-5 py-2">
        <h4>Hébergeur</h4>
        <p>
          Société FIREBASE HOSTING par GOOGLE, dont le siège social est situé au
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043
          USA.
        </p>
      </Row>
    </Container>
  );
};
export default MentionsLegales;
