import React, { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";

export const RegisterInput = ({
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  keyIndex,
  error,
}) => {
  const emailInput = useRef(null);

  useEffect(() => {
    if (keyIndex === 3) {
      if (emailInput.current) {
        emailInput.current.focus();
      }
    }
  }, [keyIndex]);
  return (
    <Container
      className="py-3 my-2 d-flex align-items-center px-5 semi-bold login-box"
      style={{ flexDirection: "column" }}
    >
      <form>
        <div
          className="w-100 my-2 user-box"
          style={{ textAlign: "-webkit-auto" }}
        >
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={"Email"}
            reference={emailInput}
            triggerError={!!error.email}
            required
          />
          <label>Email</label>
        </div>
        <div className="user-box">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={"Mot de passe"}
            className="mb-3"
            type="password"
            triggerError={!!error.password}
            required
          />
          <label>Mot de passe</label>
        </div>
        <div className="user-box">
          <input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            triggerError={!!error.password}
            required
          />
          <label>Confirmez votre mot de passe</label>
        </div>

        <p className="mt-2 white">
          En cliquant sur « Valider », j’accepte les Conditions générales de
          L'Amour Vaincra
        </p>
      </form>
    </Container>
  );
};
