import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  faChevronDown,
  faPlay,
  faUserGroup,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AuthContext } from "../../context/AuthContext";
import useWindowDimensions from "../functions/useWindowDimensions";

import Logo from "../../assets/images/logo-glow-yellow.webp";

const SM = 900;
const Header = () => {
  const [active, setActive] = useState(false);
  const { width } = useWindowDimensions();
  const { currentDBUser } = useContext(AuthContext);
  useEffect(() => {
    if (width > SM) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [width]);

  return (
    <>
      <header className="header-area header-sticky px-5">
        <Row>
          <Col md={12}>
            {/* <nav className='main-nav d-flex' style={{ alignItems: 'center' }}> */}

            <nav className="main-nav">
              {width > SM ? (
                <>
                  <Link
                    to="/"
                    className="logo link pt-0 d-flex glow-logo "
                    style={{ zIndex: 99 }}
                  >
                    <img
                      src={Logo}
                      className="glow"
                      width="80px"
                      style={{ width: "80px", height: "auto" }}
                      alt="Logo L'amour Vaincra"
                    />
                  </Link>
                  <Link
                    to="/"
                    className="logo link px-5 my-auto"
                    style={{ paddingTop: "6px", zIndex: 99 }}
                  >
                    {/* <h2 >L'AMOUR VAINCRA</h2> */}

                    <h2
                      className="link__text grow"
                      style={{ fontSize: "100%" }}
                    >
                      L'AMOUR VAINCRA
                    </h2>
                    {/* <span
                      className="link__mask-white span-h2 mx-5"
                      data-text="L'AMOUR VAINCRA"
                    /> */}
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/"
                    className="logo link pt-0 d-flex glow-logo my-auto"
                    style={{ zIndex: 99 }}
                  >
                    <img
                      src={Logo}
                      className="glow "
                      width="60px"
                      alt="Logo L'Amour Vaincra"
                      style={{
                        width: "60px",
                        height: "auto",
                        paddingTop: "18px",
                      }}
                    />
                  </Link>
                  <Link to="/" className="">
                    <h3
                      className="grow ml-3"
                      style={{
                        lineHeight: "100px",
                        textAlign: "start",
                      }}
                    >
                      L'AMOUR VAINCRA
                    </h3>
                  </Link>
                  <Link />
                </>
              )}
              {width > SM ? (
                <ul
                  className="nav"
                  style={{
                    marginTop: "17px",
                    display: "block",
                    opacity: active ? "1" : "0",
                    zIndex: active ? "10" : "-99",
                    top: active ? "0" : "-200px",
                    transition: "all 300ms ease-in-out",
                  }}
                >
                  <li className="has-sub my-auto">
                    <a
                      className={"position_center d-flex"}
                      style={{ gap: "10px" }}
                      href="/la-communauté"
                    >
                      {width > 1230 || width < SM ? (
                        "Ne ratez rien !"
                      ) : (
                        <FontAwesomeIcon
                          icon={faUserGroup}
                          className="white"
                          size="2x"
                        />
                      )}
                      <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </a>
                    <ul className="sub-menu ">
                      <li>
                        <a href="/réseaux-sociaux">Tous nos réseaux sociaux</a>
                      </li>
                      <li>
                        <a href="/se-former/liste-videos">
                          Nos listes de vidéos
                        </a>
                      </li>

                      <li>
                        <a href="/la-communauté">Les évènements à venir</a>
                      </li>
                      <li>
                        <a href="/roadtrip">Roadtrip</a>
                      </li>

                      <li>
                        <a href="/donation">Le projet à venir</a>
                      </li>
                      <li>
                        <a href="/newsletter">Newsletter</a>
                      </li>
                      <li>
                        <a href="/newsletter-aps">Newsletter Encadrant</a>
                      </li>
                    </ul>
                  </li>

                  <li className="has-sub my-auto">
                    <a
                      href="/se-former/liste-videos"
                      className={"position_center d-flex"}
                      style={{ gap: "10px" }}
                    >
                      {width > 1230 || width < SM ? (
                        "Vous nous aimez ?"
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="white"
                          size="2x"
                        />
                      )}
                      <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </a>
                    <ul className="sub-menu ">
                      <li>
                        <a href="/soutenir-shop">Le shop</a>
                      </li>
                      <li>
                        <a href="/soutenir">Les dons</a>
                      </li>
                      <li>
                        <a href="/equipe">Qui sommes-nous ?</a>
                      </li>

                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="has-sub my-auto">
                    <a
                      className={"position_center d-flex"}
                      style={{ gap: "10px" }}
                      href="/soutenir"
                    >
                      {width > 1230 || width < SM ? (
                        "Participer"
                      ) : (
                        <FontAwesomeIcon
                          icon={faSeedling}
                          className="white"
                          size="2x"
                        />
                      )}
                      <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </a>
                    <ul className="sub-menu ">
                  
                 
                    </ul>
                  </li> */}
                  {/* <li>
                    <a href='/la-boutique'>La Boutique</a>
                  </li> */}

                  <li className="my-auto">
                    <a href="/soutenir">
                      <Button
                        variant={width < SM ? "empty" : "outline-light"}
                        className={width < SM && "black"}
                      >
                        Nous soutenir
                      </Button>
                    </a>
                  </li>
                  {!!currentDBUser && currentDBUser.admin && (
                    <li className="my-auto">
                      <a href="/admin">
                        <Button
                          variant={width < SM ? "empty" : "outline-light"}
                          className={width < SM && "black"}
                        >
                          Admin
                        </Button>
                      </a>
                    </li>
                  )}

                  {/* <li>
                    {currentUser ? (
                      <div className='option' onClick={() => auth.signOut()}>
                        SIGN OUT
                      </div>
                    ) : (
                      <Link className='option' to='/signin'>
                        SIGN IN
                      </Link>
                    )}
                  </li> */}
                  {/* <li>
                    <CartIcon />
                  </li>
                  {hidden ? null : <CartDropdown />} */}
                </ul>
              ) : (
                <ul
                  className="nav"
                  style={{
                    marginTop: "17px",
                    display: "block",
                    opacity: active ? "1" : "0",
                    zIndex: active ? "10" : "-99",
                    top: active ? "0" : "-200px",
                    height: !active && "0px",
                    transition: "all 300ms ease-in-out",
                  }}
                >
                  <li>
                    <a href="/equipe">Qui sommes-nous ?</a>
                  </li>
                  <li>
                    <a href="/roadtrip">Roadtrip</a>
                  </li>
                  <li>
                    <a href="/réseaux-sociaux">Nos réseaux sociaux</a>
                  </li>
                  <li>
                    <a href="/la-communauté">Les évènements</a>
                  </li>

                  <li>
                    <a href="/se-former/liste-videos">Playlists</a>
                  </li>
                  <li>
                    <a href="/soutenir-shop">Le shop</a>
                  </li>
                  <li>
                    <a href="/soutenir">Les dons</a>
                  </li>

                  {/* 
                  <li className="my-auto">
                    <a href="/newsletter" className={"position_center d-flex"}>
                      {width > 1230 || width < SM ? (
                        "Newsletter"
                      ) : (
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="white"
                          size="2x"
                        />
                      )}{" "}
                    </a>
                  </li>
                  <li className="my-auto">
                    <a href="/soutenir">
                      <Button
                        variant={width < SM ? "empty" : "outline-light"}
                        className={width < SM && "black bold"}
                      >
                        FAIRE UN DON
                      </Button>
                    </a>
                  </li>
                  {/* <li className="my-auto">
                    <a href="/soutenir">
                      <Button
                        variant={width < SM ? "empty" : "outline-light"}
                        className={width < SM && "black bold"}
                      >
                        SOUTENIR
                      </Button>
                    </a>
                  </li> */}
                </ul>
              )}
              {active ? (
                <button
                  className="menu-trigger active btn-empty"
                  onClick={() => setActive(false)}
                >
                  <span>Menu</span>
                </button>
              ) : (
                <button
                  className="menu-trigger btn-empty"
                  onClick={() => setActive(true)}
                >
                  <span>Menu</span>
                </button>
              )}
            </nav>
          </Col>
        </Row>
      </header>

      {/* <div className='header'>
         <Link className='logo-container' to="/">
             <Logo className="logo" />
         </Link>
         <div className="options">
             <Link className="option" to='/shop'>
                 SHOP
             </Link>
             <Link className="option" to='/shop'>
                 CONTACT
             </Link>
             {
                 currentUser?(
                 <div className='option' onClick={() => auth.signOut()}>
                   SIGN OUT
                 </div>)
                 :(
                 <Link className='option' to='/signin'>
                   SIGN IN
                 </Link>)
             }
             <CartIcon />
         </div>
         {hidden? null:<CartDropdown />}
     </div> 
     */}
    </>
  );
};

export default Header;
