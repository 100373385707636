import React from 'react'
import { Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
const Input = ({
  value,
  setValue,
  label,
  type,
  triggerError = false,
  setError,
  color,
  placeholder,
  textPlaceholder,
  reference,
  fluid,
  onChange,
  name,
  height,
  ...props
}) => {
  const handleChange = value => {
    setValue(value)
  }

  return (
    <>
      {console.log(triggerError)}
      <Form.Group {...props}>
        {label !== '' && (
          <Form.Label
            style={{
              color: triggerError ? '#bc2121' : `var(--color-${color})`
            }}
            pattern='\S+'
          >
            {label}
          </Form.Label>
        )}
        <Row
          className={fluid && 'w-100'}
          style={{ paddingRight: '0.75rem', paddingLeft: '0.75rem' }}
        >
          {textPlaceholder !== '' ? (
            <>
              <InputGroup.Text
                style={{ width: '0.25rem ', height: height }}
                className={triggerError ? 'light is-invalid' : 'light'}
              >
                {textPlaceholder}
              </InputGroup.Text>

              <Form.Control
                className={triggerError ? 'mr-1 pl-5 is-invalid' : 'mr-1 pl-5'}
                style={{
                  width: '85%'
                }}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={e => {
                  if (onChange) {
                    onChange(e.target)
                  } else {
                    handleChange(e.target.value)
                  }
                }}
                disabled={props.disabled}
                value={value}
                ref={reference && reference}
              />
            </>
          ) : (
            <Form.Control
              type={type}
              placeholder={placeholder}
              name={name}
              disabled={props.disabled}
              onChange={e => {
                if (onChange) {
                  onChange(e.target)
                } else {
                  handleChange(e.target.value)
                }
              }}
              value={value}
              ref={reference && reference}
              className={triggerError ? ' is-invalid' : ''}
              invalid
            />
          )}
        </Row>
      </Form.Group>
    </>
  )
}

Input.defaultProps = {
  type: 'text',
  label: '',
  triggerError: false,
  setError: function () {},
  color: 'yellow',
  placeholder: 'Écrire ici',
  textPlaceholder: '',
  fluid: false
}

export default Input
