import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CourseHeader from "../../components/course-header/courseHeader.component";
import Course from "./course.page";
import Module from "./module.page";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const CourseLayout = () => {
  const [stepCourse, setStepCourse] = useState(1);
  const [course, setCourse] = useState({ idCourse: "", title: "" });
  const [chapter, setChapter] = useState({ idChapter: "", title: "" });
  const [module, setModule] = useState({ idModule: "", title: "" });
  const navigate = useNavigate("/");
  const location = useLocation();
  const reduxState = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    if (!!!reduxState.coursesCollection) {
      navigate("/se-former/parcours-en-ligne");
    } else {
      const locationArray = location.pathname.split("/");
      let idCourse = locationArray[3];
      let reduxCourse = reduxState.coursesCollection.filter(
        (course) => course.id === idCourse
      )[0];

      setCourse({
        idCourse: idCourse,
        title: reduxCourse.title,
        duration: reduxCourse.duration,
        length: reduxCourse.length,
      });

      //Si on est dans Module.js
      if (locationArray.length === 6) {
        let idChapter = locationArray[4];
        let idModule = locationArray[5];

        setChapter({
          idChapter: idChapter,
          title: reduxState.course.chapters[idChapter][0].titleChapter,
        });
        setModule({
          idModule: idModule,
          title: reduxState.course.chapters[idChapter].filter(
            (module) => module.id === idModule
          )[0].data.title,
        });
      } else {
        setChapter({
          idChapter: "",
          title: "",
        });
        setModule({
          idModule: "",
          title: "",
        });
      }
    }
  }, [reduxState, location, navigate]);
  useEffect(() => {
    const locationArray = location.pathname.split("/");
    if (locationArray.length === 6) {
      setStepCourse(2);
    } else if (locationArray.length === 4) {
      setStepCourse(1);
    }
  }, [location]);
  return (
    <Container
      fluid
      className="pt-5 background-white"
      style={{ minHeight: "100vh" }}
    >
      <Row className="pt-5 mt-2 position_center ">
        <h5 className="text-center yellow bold"> NOS PARCOURS EN LIGNE</h5>
      </Row>
      <CourseHeader
        course={course}
        chapter={chapter}
        module={module}
        navigate={navigate}
        setStepCourse={setStepCourse}
      />
      {stepCourse === 1 && <Course />}
      {stepCourse === 2 && <Module />}
    </Container>
  );
};
export default CourseLayout;
