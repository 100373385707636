import React from 'react'

import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

const Select = ({
  value,
  setValue,
  onChange,
  name,
  type,
  triggerError,
  setError,
  options,
  variant = 'primary',
  ...props
}) => {
  return (
    <Row {...props}>
      <Form.Select
        className={variant}
        type={type}
        value={value}
        name={name}
        onChange={e => {
          if (onChange) {
            onChange(e.target)
          } else {
            setValue(e.target.value)
          }
          setError()
        }}
        required
        style={{ borderColor: triggerError ? 'red' : 'inherit' }}
      >
        {options.map(option => {
          return (
            <option value={option} key={option}>
              {option}
            </option>
          )
        })}
      </Form.Select>
    </Row>
  )
}

export default Select

Select.defaultProps = {
  type: 'text',
  triggerError: false,
  setError: function () {},
  options: []
}
