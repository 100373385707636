import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const ChapterNavigation = () => {
  const location = useLocation();
  const reduxCourse = useSelector((state) => state.course);
  const [titleModule, setTitleModule] = useState("");
  const [urlNextCourse, setUrlNextCourse] = useState("");
  const [urlPreviousCourse, setUrlPreviousCourse] = useState("");
  const navigate = useNavigate("/");
  useEffect(() => {
    let locationArray = location.pathname.split("/");
    // console.log(locationArray);
    // if (locationArray.includes("donation")) {
    //   locationArray = [
    //     "",
    //     "",
    //     "",
    //     "apprendre-a-prier",
    //     "3-prieres",
    //     "notre-pere",
    //   ];
    // }
    const idCourse = locationArray[3];
    const idChapter = locationArray[4];
    const idModule = locationArray[5];

    console.log();
    const title =
      reduxCourse &&
      reduxCourse.chapters[idChapter].find((module) => module.id === idModule)
        .data.title;
    setTitleModule(title);

    if (
      reduxCourse &&
      reduxCourse.chapters &&
      reduxCourse.chapters[idChapter]
    ) {
      const indexModule = reduxCourse.chapters[idChapter].findIndex(
        (module) => module.id === idModule
      );
      // NEXT MODULE
      let idNextChapter = idChapter;
      let nextModule = reduxCourse.chapters[idChapter][indexModule + 1];

      //Si en dehors du cours
      if (indexModule + 1 >= reduxCourse.chapters[idChapter].length) {
        let chaptersArray = Object.entries(reduxCourse.chapters);
        let indexNextChapter =
          chaptersArray.findIndex((chapter) => chapter[0] === idChapter) + 1;
        //Si en dehors du chapitre
        if (indexNextChapter < chaptersArray.length) {
          idNextChapter = chaptersArray[indexNextChapter][0];

          nextModule = reduxCourse.chapters[idNextChapter][0];
        }
      }
      if (nextModule !== undefined) {
        setUrlNextCourse(
          `/se-former/parcours-en-ligne/${idCourse}/${idNextChapter}/${nextModule.id}`
        );
      } else {
        setUrlNextCourse("");
      }

      // PREV MODULE
      let idPrevChapter = idChapter;
      let prevModule = reduxCourse.chapters[idPrevChapter][0];

      //En dehors du chapitre
      if (indexModule - 1 < 0) {
        let chaptersArray = Object.entries(reduxCourse.chapters);
        let indexPrevChapter =
          chaptersArray.findIndex((chapter) => chapter[0] === idChapter) - 1;
        if (indexPrevChapter >= 0) {
          idPrevChapter = chaptersArray[indexPrevChapter][0];

          prevModule =
            reduxCourse.chapters[idPrevChapter][
              reduxCourse.chapters[idPrevChapter].length - 1
            ];
        }
      } else {
        prevModule = reduxCourse.chapters[idChapter][indexModule - 1];
      }
      if (prevModule.id !== idModule) {
        setUrlPreviousCourse(
          `/se-former/parcours-en-ligne/${idCourse}/${idPrevChapter}/${prevModule.id}`
        );
      } else {
        setUrlPreviousCourse("");
      }
    }
  }, [location, reduxCourse]);
  return (
    <Container
      fluid
      className="container-primary py-3 my-5 px-2 position_center flex-column"
      style={{
        backgroundColor: "white",
        maxWidth: "300px",
        position: "sticky",
        top: "150px",
      }}
    >
      <h4 className="dark-blue bebas">{titleModule}</h4>

      <div className=" mx-0 px-0">
        <Button
          variant="empty"
          className="mx-3"
          disabled={urlPreviousCourse === ""}
          onClick={() => navigate(urlPreviousCourse)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="w-auto hover-grey"
            size="2x"
          />
        </Button>
        <Button
          variant="empty"
          className="mx-3"
          disabled={urlNextCourse === ""}
          onClick={() => navigate(urlNextCourse)}
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="w-auto hover-grey"
            size="2x"
          />
        </Button>
      </div>
      <Button className="my-3" onClick={() => navigate(urlNextCourse)}>
        Cours suivant
      </Button>
    </Container>
  );
};
export default ChapterNavigation;
