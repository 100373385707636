import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QCM from "../../components/qcm/QCM";
// import module1 from "../../courses/Module-1-test/module1.html";
import "./module.scss";
import ChapterNavigation from "../../components/chapter-navigation/chapter-navigation";
import CardScore from "../../components/card-score/card-score";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
const Module = () => {
  const navigate = useNavigate("/");
  const location = useLocation();
  const reduxCourse = useSelector((state) => state.course);

  const [podcastUrl, setPodcastUrl] = useState("");
  const [content, setContent] = useState("");
  const [idCourse, setIdCourse] = useState("");
  const [idChapter, setIdChapter] = useState("");
  const [idModule, setIdModule] = useState("");
  const [qcm, setQCM] = useState();
  useEffect(() => {
    if (!!!reduxCourse) {
      navigate("/se-former/parcours-en-ligne");
    }
    const locationArray = location.pathname.split("/");
    setIdCourse(locationArray[3]);
    setIdChapter(locationArray[4]);
    setIdModule(locationArray[5]);
  }, [location]);

  useEffect(() => {
    //setContent setPodcastUrl
    if (reduxCourse && reduxCourse.chapters) {
      if (idChapter !== "") {
        let url = reduxCourse.chapters[idChapter].find(
          (module) => module.id === idModule
        ).data.podcastUrl;

        url === undefined
          ? setPodcastUrl("")
          : setPodcastUrl(url.replace("light", "extended"));
        setContent(
          reduxCourse.chapters[idChapter]
            .find((module) => module.id === idModule)
            .data.content.replace(/\\n/g, "\n")
        );
      }
    } else {
      navigate("/se-former/parcours-en-ligne");
    }

    if (idCourse && idChapter && idModule) {
      axios
        .get(`/questions/${idCourse}/${idChapter}/${idModule}`)
        .then((response) => {
          setQCM(response.data);
        });
    }
  }, [idCourse, idChapter, idModule]);

  return (
    <Container fluid className="pb-5">
      <Container className="py-5" fluid>
        <Row>
          <Col md={{ span: 2, order: 1 }} sm={{ span: 6, order: 1 }}>
            <CardScore />
          </Col>
          <Col md={{ span: 2, order: 3 }} sm={{ span: 6, order: 2 }}>
            <ChapterNavigation />
          </Col>
          <Col
            md={{ span: 8, order: 2 }}
            sm={{ span: 12, order: 5 }}
            className="px-5"
            id="course-content"
          >
            {podcastUrl !== "" && (
              <iframe
                data-role="podcasticsPlayer"
                data-format="light"
                loading="lazy"
                src={podcastUrl}
                height="200"
                width="100%"
                title="podcastics 1"
              />
            )}
            {!!content && (
              <ReactMarkdown
                components={{
                  a: ({ node, ...props }) => {
                    if (props && props.children && props.children.length > 0) {
                      const idVideo = props.children[0].split("watch?v=")[1];

                      return (
                        <Row className="position_center">
                          <div className="w-1000-md">
                            <div className="video-player-container my-5">
                              <iframe
                                className="responsive-iframe"
                                src={"https://www.youtube.com/embed/" + idVideo}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                        </Row>
                      );
                    } else {
                      return (
                        <a
                          href={props.children[0]}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn-link w-auto"
                        >
                          {props.children[0]}
                        </a>
                      );
                    }
                  },
                  h6: ({ node, ...props }) => {
                    if (props && props.children && props.children.length > 0) {
                      const codeEmoji = props.children[0][1];

                      let backgroundColor = "#f6f3f9";
                      if (codeEmoji === "\udca1") {
                        backgroundColor = "#fbf3db";
                      } else if (codeEmoji === "\udcac") {
                        backgroundColor = "#e7f3f8";
                      } else {
                        backgroundColor = "#faf1f5";
                      }
                      return (
                        <div
                          style={{
                            backgroundColor: backgroundColor,
                            padding: "10px 20px",
                            borderRadius: "5px",
                          }}
                          className="my-3"
                        >
                          {props.children[0]}
                        </div>
                      );
                    } else {
                      return <i style={{ color: "red" }} {...props} />;
                    }
                  },
                  img: ({ node, ...props }) => {
                    return (
                      <Row className="position_center w-100">
                        <img
                          src={`https://firebasestorage.googleapis.com/v0/b/l-amour-vaincra.appspot.com/o/courses%2F${idCourse}%2F${idModule}%2F${props.alt
                            .replaceAll(" ", "_")
                            .replaceAll("é", "e")}?alt=media`}
                          className="mx-5 px-5"
                          style={{ maxWidth: "400px" }}
                        />
                      </Row>
                    );
                  },
                }}
                children={content}
              />
            )}
          </Col>
        </Row>
      </Container>
      {qcm && qcm[0] && (
        <QCM
          answers={qcm[0].answers}
          correctAnswers={qcm[0].correctAnswer}
          correction=""
          question={qcm[0].question}
        />
      )}
    </Container>
  );
};
export default Module;
