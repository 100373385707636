import React, { Suspense, lazy, useContext, useLayoutEffect } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/header.component";
import CourseLayout from "./pages/cours-en-ligne/course-controler.page";

import HomePage from "./pages/homepage/homepage.page";

import axios from "axios";

import "./styles/App.scss";

import "../src/styles/buttons.scss";
import "../src/styles/templatemo-edu-meeting.scss";

import "../src/styles/images.scss";
import "../src/styles/positions.scss";
import "../src/styles/select.scss";

import "../src/styles/animations.scss";
import "../src/styles/bootstrap.min.scss";
import "../src/styles/container.scss";
import "../src/styles/input.scss";
import "../src/styles/messages.scss";
import "../src/styles/podcast.scss";
import "../src/styles/shadow.scss";
import "../src/styles/text.scss";
import "../src/styles/utils.scss";
import Footer from "./components/footer/Footer";
import { AuthContext } from "./context/AuthContext";
import Login from "./pages/auth/login.page";
import Register from "./pages/auth/register.page";
import Donation from "./pages/donation/donation.page";
import PresentationAcademyPage from "./pages/donation/presentation-academy.page";
import LAmourVaincrIA from "./pages/l-amour-vaincria/l-amour-vaincria";
import Contact from "./pages/legal/contact.jsx";
import MentionsLegales from "./pages/legal/mentions-legales";
import ThankYou from "./pages/newsletter/thank-you.page.jsx";
import Article from "./pages/shop/article.page";
import ShopCollection from "./pages/shop/shop-collection.page";
const Admin = lazy(() => import("./pages/admin/admin.page"));
const AllComponentsPage = lazy(() =>
  import("./pages/all-components/all-components.page")
);
const VideosList = lazy(() =>
  import("./pages/videos-list/videos-list.component")
);
const Participate = lazy(() => import("./pages/participer/participate.page"));
const Newsletter = lazy(() => import("./pages/newsletter/newsletter.page"));
const NewsletterAPS = lazy(() =>
  import("./pages/newsletter/newsletter-aps.page")
);
const LaCommunaute = lazy(() =>
  import("./pages/la-communauté/lacommunaute.page")
);
const Video = lazy(() => import("./pages/video/video.page"));
const Equipe = lazy(() => import("./pages/equipe/equipe.page.jsx"));
const Serie = lazy(() => import("./pages/serie/serie.page.jsx"));
const Reseaux = lazy(() => import("./pages/reseaux-sociaux/reseaux.page.jsx"));
const Soutenir = lazy(() => import("./pages/soutenir/soutenir.page"));
const Roadtrip = lazy(() => import("./pages/roadtrip/roadtrip.page"));

const ConditionsGenerales = lazy(() =>
  import("./pages/legal/ConditionsGenerales.jsx")
);

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL =
  //"http://localhost:5000/l-amour-vaincra/europe-west3/api";
  "https://europe-west3-l-amour-vaincra.cloudfunctions.net/api";

const LoadingPage = () => {
  return (
    <Container fluid className="position_center" style={{ height: "100vh" }}>
      <Spinner variant="primary" animation="border" />
    </Container>
  );
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  const { currentUser, currentDBUser } = useContext(AuthContext);
  // useEffect(() => {
  //   console.log("currentUser", currentUser);
  //   console.log("currentDBUser", currentDBUser);
  // }, [currentDBUser, currentUser]);
  const routes = [
    {
      path: "/*",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <HomePage />
        </Suspense>
      ),
    },
    {
      path: "/all-components",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <AllComponentsPage />
        </Suspense>
      ),
    },

    {
      path: "/se-former/liste-videos",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <VideosList />
        </Suspense>
      ),
    },

    {
      path: "/CGV",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <ConditionsGenerales />
        </Suspense>
      ),
    },
    {
      path: "/mentions-legales",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <MentionsLegales />
        </Suspense>
      ),
    },
    {
      path: "/contact",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Contact />
        </Suspense>
      ),
    },
    {
      path: "/soutenir",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Soutenir />
        </Suspense>
      ),
    },
    {
      path: "/donation",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Donation />
        </Suspense>
      ),
    },
    {
      path: "/course-presentation",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <PresentationAcademyPage />
        </Suspense>
      ),
    },

    {
      path: "/soutenir-shop",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <ShopCollection />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/hoodie",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/mug",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8368730866003" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/casquette",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8472344625491" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/mug",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8368730866003" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/polo-femme",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8472339939667" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/polo-homme",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8472336662867" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/tshirt-femme",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8472328601939" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir-shop/tshirt-homme",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Article idArticle="8472334532947" />
        </Suspense>
      ),
    },
    {
      path: "/soutenir/contact",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Participate />
        </Suspense>
      ),
    },
    {
      path: "/newsletter",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Newsletter />
        </Suspense>
      ),
    },
    {
      path: "/newsletter-aps",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <NewsletterAPS />
        </Suspense>
      ),
    },
    {
      path: "/la-communauté",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <LaCommunaute />
        </Suspense>
      ),
    },
    {
      path: "/video/:idVideo",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Video />
        </Suspense>
      ),
    },
    {
      path: "/serie/:idSerie",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Serie />
        </Suspense>
      ),
    },
    {
      path: "/equipe",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Equipe />
        </Suspense>
      ),
    },
    {
      path: "/réseaux-sociaux",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Reseaux />
        </Suspense>
      ),
    },

    {
      path: "/roadtrip",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Roadtrip />
        </Suspense>
      ),
    },
    {
      path: "/ia",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <LAmourVaincrIA />
        </Suspense>
      ),
    },
    {
      path: "/register",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Register />
        </Suspense>
      ),
    },
    {
      path: "/login",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/confirm",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <ThankYou />
        </Suspense>
      ),
    },
    {
      path: "/unauthorized",
      component: (
        <Container fluid>
          <p>
            Demandez l'autorisation d'accéder à cette page à
            contact@lamourvaincra.com
          </p>
        </Container>
      ),
    },
    {
      path: "/admin",
      component: (
        <Suspense fallback={<LoadingPage />}>
          {currentUser.isLoading === false &&
            currentUser.uid !== undefined &&
            (currentDBUser ? (
              currentDBUser.admin ? (
                <Admin />
              ) : (
                <Navigate to="/unautorized" replace={true} />
              )
            ) : (
              <LoadingPage />
            ))}
        </Suspense>
      ),
    },
    // {
    //   path: "/se-former/parcours-en-ligne",
    //   component: (
    //     <Suspense fallback={<LoadingPage />}>
    //       <CoursesCollection />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/se-former/parcours-en-ligne/:idCourse",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <CourseLayout />
        </Suspense>
      ),
    },
    {
      path: "/se-former/parcours-en-ligne/:idCourse/:idChapter",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <CourseLayout />
        </Suspense>
      ),
    },
    {
      path: "/se-former/parcours-en-ligne/:idCourse/:idChapter/:idModule",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <CourseLayout />
        </Suspense>
      ),
    },
  ];

  return (
    <div>
      {/* <SubHeader /> */}
      <Header />
      <Wrapper>
        <Routes>
          <React.Fragment>
            {/* <Route exact path="/" component={HomePage} /> */}
            {routes.map((route, i) => (
              <Route key={i} path={route.path} element={route.component} />
            ))}
          </React.Fragment>
        </Routes>
      </Wrapper>
      <Footer />
    </div>
  );
};

export default App;
