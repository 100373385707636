import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <Row className='w-100 background-blue-dark white py-2 px-5 mx-auto  text-center'>
      <Col md={3} sm={12}>
        @Copyright 2023 lamourvaincra.com
      </Col>
      <Col md={6} sm={12}>
        <Link to={'/CGV'} className='white'>
          CGV |{' '}
        </Link>
        <Link to={'/mentions-legales'} className='white'>
          Mentions légales |{' '}
        </Link>
        <Link to={'/contact'} className='white'>
          Contact
        </Link>
      </Col>
      <Col md={3} sm={12}>
        <a
          href={'https://kevinsananikone.com/'}
          target='_blank'
          rel='noopener noreferrer'
          className='btn-link w-auto white'
        >
          créé par kevinsananikone.com
        </a>
      </Col>
    </Row>
  )
}
export default Footer
