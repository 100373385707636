import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardCourse from "../../components/card-course/cardCourse.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptop, faMobileNotch } from "@fortawesome/pro-light-svg-icons";
import ChapterNavigationImg from "../../assets/images/chapter-navigation.webp";
import QCM from "../../components/qcm/QCM";
const PresentationAcademy = () => {
  return (
    <Container style={{ maxWidth: "1200px" }}>
      <Row className="my-2">
        <h4 className="text-center yellow bold"> NOUS SOUTENIR</h4>
        <h3>LE MOOC POUR LES PERSONNES QUI (re)Découvrent le catholicisme</h3>
      </Row>
      <Row className="">
        <Col lg={6} sm={12} className="text-start grow my-5">
          <div className="my-5">
            <h4>QUEL PUBLIC ?</h4>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                Ceux qui redécouvrent la foi
              </li>
              <li style={{ listStyleType: "disc" }}>
                Ceux qui se posent la question de la reconversion
              </li>
              <li style={{ listStyleType: "disc" }}>
                Ceux qui veulent recevoir le baptême.{" "}
              </li>
            </ul>
          </div>
          <div className="mt-5">
            <h4>WEBSITE + APPLI DÉDIÉE AVEC :</h4>
            <ul>
              <li style={{ listStyleType: "disc" }}>Version podcast</li>
              <li style={{ listStyleType: "disc" }}>
                Version texte avec vidéo pour guider
              </li>
              <li>+ Exercices pratiques, quiz, astuces, vidéos bonus. </li>
            </ul>
          </div>
          <div className="mx-auto">
            <FontAwesomeIcon icon={faLaptop} size="2x" className="mx-3" />
            <FontAwesomeIcon icon={faMobileNotch} size="2x" className="mx-3" />
          </div>
        </Col>
        <Col lg={6} sm={12} className="my-5">
          <CardCourse />
          <p className="text-center">Exemple d'un cours</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} sm={12} className="my-5">
          <img
            src={ChapterNavigationImg}
            width="400px"
            className="grow"
            style={{ maxWidth: "400px" }}
            alt="chapitre navigation"
          />
        </Col>
        <Col lg={6} sm={12} className="my-5">
          <div className="mt-5 text-start grow">
            <h4>LE CONSTAT D'UNE URGENCE</h4>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                180 000 abonnés sur YouTube en 3 ans.
              </li>
              <li style={{ listStyleType: "disc" }}>
                3 témoignages par jour de retour à la foi.
              </li>
              <li style={{ listStyleType: "disc" }}>
                30 personnes par jour qui nous contactent personnellement sur
                Instagram.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="position_center my-5">
        <div style={{ maxWidth: "1000px" }}>
          <h4 className="mb-5">AVEC UN FORMAT PODCAST</h4>
          <iframe
            data-role="podcasticsPlayer"
            data-w="900"
            data-format="light"
            loading="lazy"
            height={50}
            width="100%"
            src={
              "https://www.podcastics.com/player/light/5779/235667/?ref=https://lamourvaincra.fr/se-former/parcours-en-ligne"
            }
            title="podcastics 1"
          />
          <p>Exemple d'un podcast</p>
        </div>
      </Row>
      <Row>
        <Col lg={6} sm={12} className="my-5">
          <div className="text-start grow">
            <h4>LE PARCOURS</h4>
            <p className="bold">
              30 modules de 10 min répartis en 11 chapitres
            </p>
            <p>Sommaire de la partie d'initiation :</p>
            <ul>
              <li>Découvrir les prières de base</li>
              <li>Pourquoi Jésus ?</li>
              <li> Faire ses premiers pas dans la Bible La foi chrétienne :</li>
              <li> analyse du Credo Faire communauté :</li>
              <li> les sacrements et l'Eglise </li>
              <li>+ 7 chapitres d'approfondissement</li>
            </ul>
          </div>
        </Col>
        <Col lg={6} sm={12} className="my-5">
          <QCM />
          <p>Exemple d'un QCM intéractif</p>
        </Col>
      </Row>
    </Container>
  );
};
export default PresentationAcademy;
