import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";

const CardScore = () => {
  const [scoreQCM, setScoreQCM] = useState(0);
  const scoreRedux = useSelector((state) => state.scoreQCM);
  useEffect(() => {
    setScoreQCM(scoreRedux);
  }, [scoreRedux]);
  return (
    <Container
      fluid
      className="container-primary py-3 my-5 px-2 position_center flex-column"
      style={{
        backgroundColor: "white",
        maxWidth: "300px",
        position: "sticky",
        top: "150px",
      }}
    >
      <h4 className="dark-blue ">Score</h4>

      <p>{scoreQCM} points</p>
    </Container>
  );
};
export default CardScore;
