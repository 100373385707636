import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCoursesValidated, updateScore } from "../../redux/actions";

const QCM = ({
  question = "Combien y a-t-il de livres dans la Bible ?",
  answers = ["73", "12", "33"],
  correctAnswers = [true, false, false],
  correction = "La Bible est en réalité une bibliothèque entière composée de 73 livres. Se repérer dans la Bible, c'est comme apprendre à se repérer dans une bibliothèque",
}) => {
  const location = useLocation();

  const [answersSelected, setAnswersSelected] = useState([false, false, false]);

  const [displayCorrection, setDisplayCorrection] = useState(false);
  const [correct, setCorrect] = useState(false);
  const onChangeAnswer = (e) => {
    let answersSelectedNew = [...answersSelected];
    answersSelectedNew[e.target.value] = !answersSelectedNew[e.target.value];
    setAnswersSelected(answersSelectedNew);
  };

  const dispatch = useDispatch();
  const scoreQCM = useSelector((state) => state.scoreQCM);
  const reduxCoursesValidated = useSelector((state) => state.coursesValidated);

  const [idModule, setIdModule] = useState();
  //Check if module completed
  useEffect(() => {
    setIdModule(location.pathname.split("/")[5]);
  }, [location]);

  //Quand on passe à un nouveau chapitre rénitialise le QCM
  useEffect(() => {
    nextQuestion();
  }, [location]);

  const nextQuestion = () => {
    setAnswersSelected([false, false, false, false]);
    setDisplayCorrection(false);
  };

  const validateAnswers = () => {
    let score = 0;
    answersSelected.forEach((answerSelected, index) => {
      if (answerSelected && correctAnswers[index]) score += 1;
      if (answerSelected && !correctAnswers[index]) score -= 3;
      if (!answerSelected && correctAnswers[index]) score -= 1;
      if (!answerSelected && !correctAnswers[index]) score += 1;
    });

    if (score > 0) {
      setCorrect(true);
      const coursesValidated = reduxCoursesValidated
        ? reduxCoursesValidated
        : [];
      if (!coursesValidated.includes(idModule)) {
        dispatch(updateScore(scoreQCM + 100));
        const newCoursesValidated = [...coursesValidated, idModule];

        dispatch(addCoursesValidated(newCoursesValidated));
      }
    } else {
      setCorrect(false);
    }
    setDisplayCorrection(true);
  };
  return (
    <Container className="container-primary px-5 py-3 my-5">
      <Row>
        <h5 className="semi-bold mb-3">{question}</h5>
      </Row>
      <Row>
        {answers.map((answer, index) => (
          <div className="d-inline-flex" key={answer + index}>
            <Form.Check
              disabled={displayCorrection}
              label={answer}
              checked={answersSelected[index]}
              id={"checkbox-id" + index}
              value={index}
              className="w-auto"
              onChange={onChangeAnswer}
            />
            {displayCorrection && answersSelected[index] && (
              <p className="mb-0 ml-3 w-auto">
                {correctAnswers[index] ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="green"
                    size="1x"
                    className="w-auto"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="red"
                    size="1x"
                    className="w-auto"
                  />
                )}
              </p>
            )}
          </div>
        ))}
      </Row>
      <Row className="position_center">
        {!displayCorrection && (
          <Button
            className="w-auto"
            variant="primary"
            onClick={() => validateAnswers()}
            disabled={!answersSelected.includes(true)}
            style={{ width: "300px" }}
          >
            Valider
          </Button>
        )}
      </Row>
      {displayCorrection && (
        <>
          <Row className="my-3">
            {correct ? (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  size="2x"
                  className="w-auto"
                />
                <p className="mb-0 w-auto">Correct ! Beau travail !</p>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faXmark}
                  color="red"
                  size="2x"
                  className="w-auto"
                />
                <p className="mb-0 w-auto">Incorrect mais bien essayé !</p>
              </>
            )}
          </Row>
          <Row>{correction}</Row>
        </>
      )}
    </Container>
  );
};
export default QCM;
