import React, { useState } from "react";

export const StoreContext = React.createContext({});

const StoreAuth = ({ children }) => {
  const [userCredentials, setUserCredentials] = useState({});

  const store = {
    userCredentials,
    setUserCredentials,
  };
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
export default StoreAuth;
